import * as types from "./mutation-types";

export default {
  // Mutation to update state after successful login
  loginSuccess(state, userData) {
    state.isLoggedIn = true;
    state.userData = userData;
  },

  // Mutation to update state after successful logout
  logoutSuccess(state) {
    state.isLoggedIn = false;
    state.userData = null;
    state.permissions = [];
    state.manpowerType = null;
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
  },


  //Get User Data From LocalStorage
  setUserData(state, userData){
    state.userData = userData;
    state.isLoggedIn = true;
  },

  //updateLogin With
  setLoginWith(state, payload){
    state.loginWith = payload;
  },

  setAwaitingOTP(state, payload){
    state.awaitingOTP = payload
  },

  setUser(state, payload){
    state.user = payload
  },

  setManpowerType(state, payload){
    state.manpowerType = payload
  },
  setPermissions(state, payload){
    state.permissions = payload
  }
};
