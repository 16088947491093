import * as types from "./mutation-types";

export default {
    RESET_STATE(state) {
        Object.assign(state, {
            customers:[],
            customer:null,
            addedCustomer:null,
            addedDocument:null,
            addedDocuments:[],
        });
    },
    setCustomers(state, payload) {
        state.customers = payload;
    },
    setCustomersView(state, payload) {
        state.customers_view = payload;
    },
    setAddedCustomer(state, payload) {
        state.addedCustomer = payload;
    },
    setAddedDocument(state, payload) {
        state.addedDocument = payload;
    },
    setAddedDocuments(state, payload) {
        state.addedDocuments = payload;
    },
    setCustomer(state, payload) {
        state.customer = payload;
    },
};
