<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/">
              <img src="/img/final-logo.png" class="navbar-logo" alt="logo" />
            </router-link>
          </li>
          <li class="nav-item theme-text">
            <router-link to="/" class="nav-link">EPS</router-link>
          </li>
        </ul>
        <div class="d-none horizontal-menu">
          <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </a>
        </div>
        <ul class="navbar-item flex-row ml-md-0 ml-auto"></ul>

        <div class="navbar-item flex-row ml-md-auto">
          <!-- <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="nav-link" class="nav-item message-dropdown" :right="true">
            <template #button-content>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
            </template>

            <b-dropdown-item>
              <b-media class="media">
                <template #aside>
                  <div class="avatar avatar-xl">
                    <span class="avatar-title rounded-circle">KY</span>
                  </div>
                </template>
                <h5 class="usr-name">Kara Young</h5>
                <p class="msg-title">ACCOUNT UPDATE</p>
              </b-media>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-media class="media">
                <template #aside>
                  <img src="@/assets/images/profile-15.jpeg" alt="avatar" />
                </template>
                <h5 class="usr-name">Daisy Anderson</h5>
                <p class="msg-title">ACCOUNT UPDATE</p>
              </b-media>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-media class="media">
                <template #aside>
                  <div class="avatar avatar-xl">
                    <span class="avatar-title rounded-circle">OG</span>
                  </div>
                </template>
                <h5 class="usr-name">Oscar Garner</h5>
                <p class="msg-title">ACCOUNT UPDATE</p>
              </b-media>
            </b-dropdown-item>
          </b-dropdown> -->

          <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="nav-link" menu-class="notification-scroll" class="nav-item notification-dropdown" :right="true" @show="updateNotifications">
            <template #button-content >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>
              <span class="badge badge-success" v-if="hasActiveNotifications"></span>
            </template>
            <b-dropdown-item v-if="notifications && notifications.length <= 0">
                <p>No New Notifcations</p>
            </b-dropdown-item>
            <div v-for="(item, index) in notifications" :key="index">
            <b-dropdown-item>
              <b-media class="server-log">
                <template #aside>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-server"
                  >
                    <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
                    <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
                    <line x1="6" y1="6" x2="6" y2="6"></line>
                    <line x1="6" y1="18" x2="6" y2="18"></line>
                  </svg>
                </template>
                <div class="data-info">
                  <h6 class="">{{item.name || null}}</h6>
                  <p class="">{{ formatShortDate(item.time) }}</p>
                </div>

                <div class="icon-status">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </div>
              </b-media>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            </div>
          </b-dropdown>

          <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="user nav-link" class="nav-item user-profile-dropdown" :right="true">
            <template #button-content>
              <b-avatar :src="avatarUrl" size="2.5rem" rounded="sm" class=""></b-avatar>

              <span
                class="badge badge-success"
                style="
                  position: absolute;
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  padding: 0;
                  font-size: 10px;
                  color: #fff !important;
                  background: #1abc9c;
                  top: -2px;
                  right: -2px;
                "
              v-if="$store.state.employees.isOnline === true"></span>
              <span
                class="badge badge-success"
                style="
                  position: absolute;
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  padding: 0;
                  font-size: 10px;
                  color: #fff !important;
                  background: red;
                  top: -2px;
                  right: -2px;
                "
              v-else></span>
            </template>

            <b-dropdown-item to="/profile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              Profile
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <!-- <b-dropdown-item to="/apps/mailbox">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-inbox"
                            >
                                <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                                <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                            </svg>
                            Inbox
                        </b-dropdown-item> -->
            <b-dropdown-divider></b-dropdown-divider>
            <!-- <b-dropdown-item to="/auth/lockscreen">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-lock"
                            >
                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                            </svg>
                            Lock Screen
                        </b-dropdown-item> -->
            <!-- <b-dropdown-divider></b-dropdown-divider> -->
            <b-dropdown-item @click="logoutHandler">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-log-out"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              Sign Out
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN NAVBAR  -->
    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </a>

        <!-- Portal vue for Breadcrumb -->
        <portal-target name="breadcrumb"> </portal-target>
      </header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN TOPBAR  -->
    <div class="topbar-nav header navbar" role="banner">
      <nav class="topbar">
        <ul class="list-unstyled menu-categories" id="topAccordion">
          <router-link tag="li" to="/" class="menu single-menu">
            <a>
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                <span>{{ $t('dashboard') }}</span>
              </div></a
            ></router-link
          >
          <!-- EMPLOYEES -->

          <li class="menu single-menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_employees')">
            <a href="javascript:;" data-toggle="collapse" aria-expanded="true" class="dropdown-toggle autodroprown">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-shield"
                  data-v-02c2cbc4=""
                >
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
                <span>{{ $t('employees') }}</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <ul class="collapse submenu list-unstyled" id="employees" data-parent="#topAccordion">
              <router-link tag="li" to="/employees"><a>All Employees</a></router-link>
              <router-link tag="li" to="/employees/add" v-if="permissions && permissions.length > 0 && permissions.includes('nav_employees_add')"><a>Add Employees</a></router-link>
              <router-link tag="li" to="/employees/assignments" v-if="permissions && permissions.length > 0 && permissions.includes('nav_assignments')"><a>Assignments</a></router-link>
              <router-link tag="li" to="/employees/attendances" v-if="permissions && permissions.length > 0 && permissions.includes('nav_attendances')"><a>Attendances</a></router-link>
            </ul>
          </li>
          <!-- CUSTOMERS -->
          <li class="menu single-menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_customers')">
            <a href="javascript:;" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle autodroprown">
              <div class="">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 1h12M3 1v16M3 1H2m13 0v16m0-16h1m-1 16H3m12 0h2M3 17H1M6 4h1v1H6V4Zm5 0h1v1h-1V4ZM6 8h1v1H6V8Zm5 0h1v1h-1V8Zm-3 4h2a1 1 0 0 1 1 1v4H7v-4a1 1 0 0 1 1-1Z"
                  />
                </svg>
                <span>{{ $t('customers') }}</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <ul class="collapse submenu list-unstyled" id="customers" data-parent="#topAccordion">
              <router-link tag="li" to="/customers"><a>All Customers</a></router-link>
              <router-link tag="li" to="/customers/add" v-if="permissions && permissions.length > 0 && permissions.includes('nav_customers_add')"><a>Add Customers</a></router-link>
              <router-link tag="li" to="/customers/contracts" v-if="permissions && permissions.length > 0 && permissions.includes('nav_contracts')"><a>Contracts</a></router-link>
            </ul>
          </li>

          <!-- VENDORS -->
          <li class="menu single-menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_vendors')">
            <a href="javascript:;" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle autodroprown">
              <div class="">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3.308 9a2.257 2.257 0 0 0 2.25-2.264 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 1 0 4.5 0C19.058 5.471 16.956 1 16.956 1H3.045S1.058 5.654 1.058 6.736A2.373 2.373 0 0 0 3.308 9Zm0 0a2.243 2.243 0 0 0 1.866-1h.767a2.242 2.242 0 0 0 3.733 0h.767a2.242 2.242 0 0 0 3.733 0h.767a2.247 2.247 0 0 0 1.867 1A2.22 2.22 0 0 0 18 8.649V19H9v-7H5v7H2V8.524c.37.301.83.469 1.308.476ZM12 12h3v3h-3v-3Z"
                  />
                </svg>
                <span>{{ $t('vendors') }}</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <ul class="collapse submenu list-unstyled" id="vendors" data-parent="#topAccordion">
              <router-link tag="li" to="/vendors"><a>All Vendors</a></router-link>
              <router-link tag="li" to="/vendors/add" v-if="permissions && permissions.length > 0 && permissions.includes('nav_vendors_add')"><a>Add Vendors</a></router-link>
              <router-link tag="li" to="/vendors/inventory" v-if="permissions && permissions.length > 0 && permissions.includes('nav_inventories')"><a>Inventory</a></router-link>
            </ul>
          </li>
          <!-- Requests -->
          <li class="menu single-menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests')">
            <a href="javascript:;" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle autodroprown">
              <div class="">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                </svg>
                <span>Requests</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <ul class="collapse submenu list-unstyled" id="requests" data-parent="#topAccordion">
              <router-link tag="li" to="/employees/requests/leave" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_leave')"><a>Leave Applications</a></router-link>
              <router-link tag="li" to="/employees/requests/advance" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_advance')"><a>Advance Requests</a></router-link>
              <router-link tag="li" to="/employees/requests/inventory" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_inventory')"><a>Inventory Requests</a></router-link>
              <router-link tag="li" to="/employees/requests/transfer" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_transfer')"><a>Transfer Requests</a></router-link>
            </ul>
          </li>
          <li class="menu single-menu">
            <a href="javascript:;" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle autodroprown">
              <div class="">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                </svg>
                <span>Extras</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <ul class="collapse submenu list-unstyled" id="extras" data-parent="#topAccordion">
              <router-link tag="li" to="/pages/training-materials"><a>Training Materials</a></router-link>
              <li><a href="tel:919322295293">Contact Us</a></li>
              <router-link tag="li" to="/pages/about-us"><a>About Us</a></router-link>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <!--  END TOPBAR  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedLang: null,
      countryList: this.$store.state.countryList,
      // notifications: [],
      hasActiveNotifications: false
    };
  },
  computed: {
    employee() {
      // return this.$store.state.employees.employee
      return this.$store.getters['employees/getAuthEmployee'];
    },
    permissions() {
      return this.$store.state.auth.permissions;
    },
    avatarUrl() {
      if (this.employee && this.employee.EmployeeDocuments && this.employee.EmployeeDocuments.length > 0) {
        let documents = this.employee.EmployeeDocuments;
        return documents.find((doc) => doc.isProfilePic).storage_url;
      } else {
        return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
      }
    },
    notifications() {
      return this.$store.state.employees.notifications;
    },
  },
  watch: {
    notifications: {
      handler(newVal, oldVal) {
          if (newVal && newVal.length > 0) {
            let flag = 0;
            const items = newVal.filter((item) => item.status === 'pending');
            if(items && items.length > 0){
              flag = 1;
            }
            if(flag === 1){
              this.hasActiveNotifications = true;
            }else{
              this.hasActiveNotifications = false;
            }
          }
      },
      deep: true
    }
    // notifications(newVal, oldVal) {
    //   console.log('updated')
    //   if (newVal.length > 0) {
    //     console.log('updated')
    //     let flag = 0;
    //     const items = newVal.filter((item) => item.status === 'pending');
    //     if(items && items.length > 0){
    //       flag = 1;
    //     }
    //     if(flag === 1){
    //       this.hasActiveNotifications = true;
    //     }else{
    //       this.hasActiveNotifications = false;
    //     }
    //   }
    // }
  },
  async mounted() {
    console.log('MOUNTED HEADER');
    this.selectedLang = this.$appSetting.toggleLanguage();
    this.toggleMode();
    this.$store.dispatch('employees/requestNotificationPermission');
  },
  methods: {
    updateNotifications() {
      console.log('UPDATE NOTIFICATIONS')
      this.$store.commit('employees/updateAllNotificationToRead');
    },
    formatShortDate(date) {
      const curentDate = this.$moment(date).fromNow();
      return curentDate;
    },
    toggleMode(mode) {
      this.$appSetting.toggleMode(mode);
    },

    changeLanguage(item) {
      this.selectedLang = item;
      this.$appSetting.toggleLanguage(item);
    },
    async logoutHandler() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
};
</script>
