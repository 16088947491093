import * as types from "./mutation-types";

export default {
    RESET_STATE(state) {
        Object.assign(state, {
            manpower_types:[],
            rate_cards:[],
            addedUser:null,
            addedManPowerType:null,
            addedRateCard:null,
            addedContract:null,
            contracts:[],
            addedContractDetail:null,
            contract_details:[],
            contract_detail:null,
            addedShift:null,
            shifts:[],
            employee_assignments:[],
            addedTransaction: null,
            transactions: [],
            transaction: null,
        });
    },
    setManpowerTypes(state, data) {
        state.manpower_types = data;
    },
    setRateCards(state, data) {
        state.rate_cards = data;
    },
    setAddedUser(state, data) {
        state.addedUser = data;
    },
    setAddedManPowerType(state, data) {
        state.addedManPowerType = data;
    },
    setAddedRateCard(state, data) {
        state.addedRateCard = data;
    },
    //CONTRACT
    setAddedContract(state, data) {
        state.addedContract = data;
    },
    setContracts(state, data) {
        state.contracts = data;
    },
    
    //CONTRACT Detail
    setAddedContractDetail(state, data) {
        state.addedContractDetail = data;
    },
    
    setContractDetails(state, data) {
        state.contract_details = data;
    },

    setContractDetail(state, data) {
        state.contract_detail = data;
    },

    //Shift
    setAddedShift(state, data) {
        state.addedShift = data;
    },
    setShifts(state, data) {
        state.shifts = data;
    },

    //Employee Assignments
    setEmployeeAssignments(state, data) {
        state.employee_assignments = data;
    },

    //Transactions
    setAddedTransaction(state, data) {
        state.addedTransaction = data;
    },
    setTransaction(state, data) {
        state.transaction = data;
    },
    setTransactions(state, data) {
        state.transactions = data;
    },
};
