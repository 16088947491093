import axios from 'axios';
import { encryptData, decryptData } from '../../../utils/encryption';
import store from '.';

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL :  process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL :  process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL

export default {
  // Action to perform login
  async emailLogin({ commit }, { email, password }) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { email, password });
      const userData = response.data;
      if(userData.status){
        // Encrypt the user data before storing it in localStorage
        const encryptedUserData = encryptData(userData);
        // Store the user data and token in local storage for persistent session
        localStorage.setItem('userData', encryptedUserData);
        localStorage.setItem('token', userData.token); // Assuming the token is available in the response
        
        if(userData.user.Roles && userData.user.Roles.length > 0){
          const allPermissions = Array.from(new Set(userData.user.Roles.flatMap(role => (
            role.Permissions.map(permission => permission.name)
          ))));          
          commit('setPermissions', allPermissions)
        }else{
          commit('setPermissions', [])
        }
        
        // Commit a login success mutation with user data
        commit('loginSuccess', userData.user);
      }else{
        throw new Error('Login failed. Please check your credentials.'); // Custom error message for API errors
      }
    } catch (error) {

      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
  },

  async passwordReset({ commit }, {email, phoneNumber}) {
    
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/resetlink`, { phoneNumber, email });
      
      const res = response.data;
      if(res.status){
        if(res.otp){
          commit('setAwaitingOTP', true);
        }else{
          commit('setAwaitingOTP', false);
          throw new Error(`Error Occured!`); // Custom error message for API errors
        }
      }else{
        throw new Error(`Login failed. Please check your credentials.${JSON.stringify(response.data)}`); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
  },

  // Action to perform login
  async phoneLogin({ commit }, { phoneNumber, password }) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { phoneNumber, password });
      
      const userData = response.data;
      if(userData.status){
        if(userData.otp){
          commit('setAwaitingOTP', true);
        }else{
          commit('setAwaitingOTP', false);
         // Encrypt the user data before storing it in localStorage
          const encryptedUserData = encryptData(userData);
          // Store the user data and token in local storage for persistent session
          localStorage.setItem('userData', encryptedUserData);
          localStorage.setItem('token', userData.token); // Assuming the token is available in the response
          
          if(userData.user.Roles && userData.user.Roles.length > 0){
            const allPermissions = Array.from(new Set(userData.user.Roles.flatMap(role => (
              role.Permissions.map(permission => permission.name)
            ))));          
            commit('setPermissions', allPermissions)
          }else{
            commit('setPermissions', [])
          }
          
          // Commit a login success mutation with user data
          commit('loginSuccess', userData.user);
        }
      }else{
        throw new Error(`Login failed. Please check your credentials.${JSON.stringify(response.data)}`); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
  },

  // Action to perform login
  async verifyPhoneOTP({ commit }, { phoneNumber, otp, email=null }) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/verifyOtp`, { phoneNumber, otp, email });
      const userData = response.data;
      if(userData.status){
        // Encrypt the user data before storing it in localStorage
        const encryptedUserData = encryptData(userData);
        // Store the user data and token in local storage for persistent session
        localStorage.setItem('userData', encryptedUserData);
        localStorage.setItem('token', userData.token); // Assuming the token is available in the response
        // Commit a login success mutation with user data
        commit('loginSuccess', userData.user);
      }else{
        throw new Error('Login failed. Please check your credentials.'); // Custom error message for API errors
      }
    } catch (error) {

      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
  },

   // Action to perform Profile Update
   async updateProfile({ commit }, user) {
    console.log('USER_UPDATING-', user)
    if(!user.id) return;
    if(user.phoneNumber) user.phoneNumber = user.phoneNumber.replace(/\D/g, '');
    try {
      const response = await axios.put(`${API_BASE_URL}/api/profile`,  user ,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
       });
      const userData = response.data;
      if(userData.status){
        console.log('USER_UPDATED-', userData.user)
        const encryptedUserData = encryptData(userData);
        // Store the user data and token in local storage for persistent session
        localStorage.setItem('userData', encryptedUserData);
        commit('setUserData', userData.user);
      }else{
        throw new Error(`Update failed. Please check your credentials.${JSON.stringify(response.data)}`); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
      
    }
  },
  // Action to perform logout
  async logout({ commit, dispatch }) {
    try {
      
      // Commit a logout success mutation
      commit('logoutSuccess');
      dispatch('resetAllState')
    } catch (error) {
      // Handle logout failure here (e.g., show error messages)
      console.error('Logout error:', error);
    }
  },
  async resetAllState({ commit }) {
    try {
      
      // Commit a logout success mutation
      commit('employees/RESET_STATE', null, { root: true });
      commit('business/RESET_STATE', null, { root: true });
      commit('customers/RESET_STATE', null, { root: true });
    } catch (error) {
      // Handle logout failure here (e.g., show error messages)
      console.error('Logout error:', error);
    }
  },

  async getUserData({ rootState, dispatch, commit }) {
    
    const encryptedUserData = localStorage.getItem('userData');
    const token = localStorage.getItem('token');
    if (encryptedUserData && token) {
      // Decrypt the user data
      const userData = decryptData(encryptedUserData);

      try{
        const response = await axios.get(`${API_BASE_URL}/api/profile`, { 
          headers:{
              Authorization: `Bearer ${token}`,
          }
         });

        if((userData.user.id && !response.data.user) || !userData.user.id){
          commit('logoutSuccess');
          dispatch('resetAllState')
        }else{
          if(userData.user.Roles && userData.user.Roles.length > 0){
            const allPermissions = Array.from(new Set(userData.user.Roles.flatMap(role => (
              role.Permissions.map(permission => permission.name)
            ))));        
            if(userData.user.user_type === 'staff'){
              commit('setLayout', "staff", { root: true });
              const employee = rootState.employees.employee;
              console.log('Emp Exist', employee && employee.name)
              if (!employee || (employee && employee.user_id !== userData.user.uuid)) {
                console.log('Calling Emp Auth 2')
                dispatch('employees/getEmployeeByAuth',userData.user.uuid, { root: true })  
              }
            }
            commit('setPermissions', allPermissions)
          }else{
            commit('setPermissions', [])
          }
          commit('setUserData', userData.user);
        }
      }catch(error){
        commit('logoutSuccess');
        dispatch('resetAllState')
      }
      //Verify Token
      
    }else{
      console.log('TOKEN AND ENCRYP DATA NOT FOUND')
    }
  },
  
  async getUserByUUID({commit}, uuid){
    console.log('GET USER BY UUID - ', uuid)
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/uuid/${uuid}`,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if(response.data.status){
        commit('setUser', response.data.user);
      }else{
        throw new Error('Cannot Get User');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },

  async getUsersStaff({ commit }) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/employees/user_staff_view`, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const user_staff_view = response.data.user_staff_view
      if(user_staff_view){
        // return response.data.employee_assignments_view; // Returning the result directly
        return user_staff_view; // Returning the result directly
      }else{
        return []
      }
      
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
    }
  },

  // roles & permissions
  async getRoles({ commit }) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/roles`, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const roles = response.data.roles
      if(roles){
        // return response.data.employee_assignments_view; // Returning the result directly
        return roles; // Returning the result directly
      }else{
        return []
      }
      
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
    }
  },
  async getPermissions({ commit }) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/permissions`, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const permissions = response.data.permissions
      if(permissions){
        // return response.data.employee_assignments_view; // Returning the result directly
        return permissions; // Returning the result directly
      }else{
        return []
      }
      
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

          // Check if the error is a response from the server with structured data
          if (error.response && error.response.data) {
              if (error.response.data.errors && error.response.data.errors.length > 0) {
                  // If there are multiple validation errors, concatenate their messages
                  errorMessage = error.response.data.errors.map(err => err.message).join(", ");
              } else {
                  // Use the main error message if available
                  errorMessage = error.response.data.message || errorMessage;
              }
          } else if (error instanceof Error) {
              // Handle generic Error instances
              errorMessage = error.message;
          } else {
              // Handle cases where error is not an instance of Error
              errorMessage = `Error - ${error.toString()}`;
          }
      
          throw new Error(errorMessage);
    }
  },
  async getRole({commit}, id){
    try {
      const response = await axios.get(`${API_BASE_URL}/api/roles/${id}`,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if(response.data.status){
        return response.data.role
      }else{
        throw new Error(`Cannot Get Role By id ${id}`);
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },
  async addRole({commit}, role){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/roles/add`,role, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if(response.data.status){
        return response.data.role
      }else{
        throw new Error(`Cannot Add Role`);
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },
  async addUserRole({commit}, userRole){
    try {
      const response = await axios.post(`${API_BASE_URL}/api/users/roles/add`,userRole, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if(response.data.status){
        return response.data.userRoles
      }else{
        throw new Error(`Cannot Add Role`);
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },
  async updateRoleWithPermissions({commit}, editRole){
    
    let rolePermissionsdata = {
      roleId: editRole.role_id,
      permissions: editRole.permission
    }
    try {
      const response = await axios.put(`${API_BASE_URL}/api/roles/${editRole.role_id}`, editRole, { 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if(response.data.status){
        const responsePermission = await axios.post(`${API_BASE_URL}/api/roles/permissions/add`, rolePermissionsdata, { 
          headers:{
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
          }
        });
        if(responsePermission.data.status){
          return response.data.role
        }
      }else{
        throw new Error(`Cannot Get Role By id ${editRole.id}`);
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },
};
