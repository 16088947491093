<template>
  <div class="d-flex flex-column justify-content-center mb-2 border border-primary rounded p-2 mt-3">
    <div class="d-flex justify-content-center flex-column m-2">
      <h6 class="text-center">Attendance</h6>
      <p class="text-center text-muted">{{ currentTime }}</p>
    </div>
    <div class="loader mx-auto" v-if="asyncLoading"></div>
    <div class="d-flex flex-column justify-content-center" v-if="currentEmpAssignment && !asyncLoading">
      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-column">
          <p class="text-muted">
            Assigned -
            <template v-if="other_attendance && other_attendance.id">
              <b-badge v-if="['present', 'approved'].includes(other_attendance.status)" variant="outline-success" class="outline-badge-success mr-1">
                {{ other_attendance.status }}
              </b-badge>
              <b-badge v-else-if="other_attendance.status === 'marked'" variant="outline-info" class="outline-badge-info mr-1">
                {{ other_attendance.status }}
              </b-badge>
              <b-badge v-else-if="other_attendance.status === 'escalated'" variant="outline-warning" class="outline-badge-warning mr-1">
                {{ other_attendance.status }}
              </b-badge>
              <b-badge v-else-if="['declined', 'absent'].includes(other_attendance.status)" variant="outline-danger" class="outline-badge-danger mr-1">
                {{ other_attendance.status }}
              </b-badge>
            </template>
            <span v-else>NA</span>
          </p>

          <p class="mb-1">{{ currentEmpAssignment.customerName }}</p>
          <p class="mb-1">{{ currentEmpAssignment.shiftName }}</p>
          <p class="mb-1">{{ formatTime(currentEmpAssignment.shiftStartTime) }} - {{ formatTime(currentEmpAssignment.shiftEndTime) }}</p>
          <p class="mb-1">Income - <span>&#8377;</span> {{ Math.round(currentEmpAssignment.earnings) }}</p>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <b-button
            variant="success"
            class="mb-1"
            @click="markPresent"
            v-if="!other_attendance || !other_attendance.id || !['present', 'approved', 'escalated', 'marked', 'declined'].includes(other_attendance.status)"
          >
            P
          </b-button>
          <b-button
            variant="danger"
            class="mb-1"
            @click="markAbsent"
            v-if="!other_attendance || !other_attendance.id || !['absent', 'approved', 'escalated', 'marked', 'declined'].includes(other_attendance.status)"
          >
            A
          </b-button>
          <b-button
            variant="success"
            class="mb-1"
            @click="markApprove"
            v-if="other_attendance && other_attendance.id && ['marked', 'escalated', 'declined'].includes(other_attendance.status) && !['present', 'absent'].includes(other_attendance.status)"
          >
            Approve
          </b-button>
          <b-button
            variant="danger"
            class="mb-1"
            @click="markDecline"
            v-if="other_attendance && other_attendance.id && ['marked', 'escalated', 'approved'].includes(other_attendance.status) && !['present', 'absent'].includes(other_attendance.status)"
          >
            Decline
          </b-button>
          <b-button variant="warning" class="" @click="replaceEmployee">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-repeat"
              data-v-02c2cbc4=""
            >
              <polyline points="17 1 21 5 17 9"></polyline>
              <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
              <polyline points="7 23 3 19 7 15"></polyline>
              <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
            </svg>
          </b-button>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between mb-1 mt-1" v-if="other_attendance.id && other_attendance.status === 'marked' && other_attendance.check_in_time">
        <p class="text-muted">Checked In : </p>
        <p >{{ formatDate(other_attendance.check_in_time) }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between mb-1 mt-1" v-if="other_attendance.id && other_attendance.status === 'marked' && other_attendance.check_out_time">
        <p class="text-muted">Checked Out : </p>
        <p >{{ formatDate(other_attendance.check_out_time) }}</p>
      </div>
    </div>

    <div class="d-flex justify-content-center flex-column m-2" v-else>
      <p class="text-center text-muted">Unassigned</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeAttendanceCard',
  props: {
    initialCurrentEmpAssignment: {
      type: Object,
      required: true,
    },
    initialOtherAttendance: {
      type: Object,
      required: true,
      // Add more specific validation if necessary
    },
    onAttendanceMarked: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      asyncLoading: false,
      currentTime: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      timer: null,
      currentEmpAssignment: { ...this.initialCurrentEmpAssignment },
      other_attendance: { ...this.initialOtherAttendance },
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
    }, 1000); // Update time every second
  },
  destroyed() {
    clearInterval(this.timer); // Clear the interval when the component is destroyed
  },
  watch: {
    initialCurrentEmpAssignment(newVal) {
      this.currentEmpAssignment = newVal;
    },
    initialOtherAttendance(newVal) {
      this.other_attendance = newVal;
    },
  },

  methods: {
    formatDate(date) {
      return this.$moment(date).format('lll');
    },
    formatTime(time) {
      let timeToFormat = '2000-01-01 ' + time;
      return this.$moment(timeToFormat).format('LT');
    },
    async markPresent() {
      this.updateAttendance('present');
    },

    async markAbsent() {
      await this.confirmAction(this.updateAttendance, 'absent', 'Do you want to mark as absent?');
    },

    async markApprove() {
      if (this.other_attendance.status === 'marked' || this.other_attendance.status === 'declined') {
        this.updateAttendance('approved');
      }
    },

    async markDecline() {
      await this.confirmAction(this.updateAttendance, 'declined', 'Do you want to decline the attendance?');
    },
    async confirmAction(action, status, message) {
      try {
        const result = await this.$swal({
          title: 'Are you sure?',
          text: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!',
        });

        if (result.isConfirmed) {
          await action(status);
        }
      } catch (error) {
        console.error('Error during confirmation:', error);
      }
    },
    isNightShift(startTime, endTime) {
      console.log('NIGHT SHIT before-', startTime, endTime);
      // Parse the start and end times
      const start = this.$moment(startTime, 'HH:mm');
      const end = this.$moment(endTime, 'HH:mm');

      console.log('NIGHT SHIT-', start, end);

      // If start time is greater than or equal to end time, the shift spans over midnight
      if (start.isSameOrAfter(end)) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentDate(assignmentShiftStartTime, assignmentShiftEndTIme) {
      const currentDate = this.$moment(); // Example current time
      // const currentDate = this.$moment('2023-12-23 23:55', 'YYYY-MM-DD HH:mm');

      let newDate = currentDate.clone();
      const nightShift = this.isNightShift(assignmentShiftStartTime, assignmentShiftEndTIme);
      if (nightShift) {
        const startTime = this.$moment(assignmentShiftStartTime, 'HH:mm');
        const endTime = this.$moment(assignmentShiftEndTIme, 'HH:mm');

        if (currentDate.hour() >= 0 && currentDate.hour() < startTime.hour()) {
          // Current time is after midnight but before the start of the shift
          newDate = startTime.clone().subtract(1, 'day');
        } else {
          // Current time is before midnight or after the start of the shift
          endTime.add(1, 'day');
        }

        if (currentDate.isAfter(startTime) && currentDate.isBefore(endTime)) {
          // Current time falls within the shift period
          return newDate;
        }
      }
      return newDate;
    },
    async updateAttendance(status, notes = 'qr scanned') {
      if (!this.currentEmpAssignment || !this.currentEmpAssignment.assignmentId || !this.currentEmpAssignment.contractDetailId || !this.currentEmpAssignment.employeeId) {
        return;
      }

      this.asyncLoading = true;
      try {
        const currentDate = this.getCurrentDate(this.currentEmpAssignment.shiftStartTime, this.currentEmpAssignment.shiftEndTime);
        const attendanceData = {
          date: currentDate.format('YYYY-MM-DD'),
          status: status,
          notes: notes,
        };

        if (!this.other_attendance.id) {
          // Add new attendance
          await this.$store.dispatch('employees/addEmployeeOtherAttendance', {
            ...attendanceData,
            employeeId: this.currentEmpAssignment.employeeId,
            assignmentId: this.currentEmpAssignment.assignmentId,
            contractDetailId: this.currentEmpAssignment.contractDetailId,
            check_in_time: null,
            check_out_time: null,
            approved_by: null,
          });
        } else if (this.other_attendance.status !== status) {
          // Update existing attendance
          await this.$store.dispatch('employees/updateEmployeeOtherAttendance', {
            id: this.other_attendance.id,
            ...attendanceData,
          });
        }

        this.other_attendance = {
          ...this.$store.state.employees.addedEmployeeOtherAttendance,
        };
        this.onAttendanceMarked(this.other_attendance);
      } catch (error) {
        console.error('Error updating attendance:', error);
        this.$swal('Error', 'Failed to update attendance.', 'error');
      } finally {
        this.asyncLoading = false;
      }
    },
    replaceEmployee() {},
  },
};
</script>

<style scoped>
</style>
