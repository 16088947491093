<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component v-bind:is="layout"></component>
  </div>
</template>
<script>
import messaging from '@/firebaseInit';
import { onMessage } from 'firebase/messaging';
import Swal from 'sweetalert2';
// layouts
import appLayout from './layouts/app-layout.vue';
import staffAppLayout from './layouts/staff-app-layout.vue';
import authLayout from './layouts/auth-layout.vue';

import '@/assets/sass/app.scss';

export default {
  metaInfo: {
    title: 'Escorts Security Group',
    titleTemplate: '%s | Escorts Security Group - APP',
  },
  components: {
    app: appLayout,
    auth: authLayout,
    staff: staffAppLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  data() {
    return {
      deferredPrompt: null, // Used to store the event
    };
  },
  created() {
    this.listenForMessages();
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Show the SweetAlert2 install modal
      this.showInstallModal();
    });
  },
  mounted() {},
  methods: {
    listenForMessages() {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // Here, you can implement additional logic based on the message
        // For example, showing a notification or updating the state
        Swal.fire({
          title: payload.notification.title,
          text: payload.notification.body,
          icon: 'info',
          confirmButtonText: 'Okay',
        });
      });
    },

    showInstallModal() {
  this.$swal({
    toast: true, // Enable toast mode
    position: 'bottom', // Position at the bottom
    showConfirmButton: true, // Show the confirm button
    confirmButtonText: 'Install', // Text for the confirm button
    showCancelButton: false, // Show the cancel button (acts as the 'x' to close)
    showCloseButton: true, // Shows a close button
    confirmButtonColor: '#b31f20',
    timer: 10000, // Auto-close after 10 seconds (optional)
    timerProgressBar: true, // Shows a timer progress bar (optional)
    // title: 'Install Our Awesome App', // Your message
    width: 350, // Width of the toast
    padding: '0.5rem', // Padding inside the toast
    backdrop: false, // No backdrop for a less intrusive prompt
    html:
      '<img src="/img/final-logo.png" style="vertical-align: middle; width: 30px; height: 30px; margin-right: 10px;">' +
      '<span style="vertical-align: middle; color:white">Install Our Awesome App</span>',
      customClass: {
      // Use Bootstrap's 'd-flex' and 'justify-content-center' to center the button
      actions: 'd-flex justify-content-center',
    },
  }).then((result) => {
    if (result.isConfirmed && this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;
      });
    }
  });
},
  },
};
</script>
