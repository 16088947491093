const Module = () => import("./Module.vue");
const AssignEmployees = () => import("./views/AssignEmployees.vue");
const TestAssignment = () => import("./views/TestAssignment.vue");
import Store from '../../store'; // Adjust the path as necessary to import your store


const moduleRoute = {
  path: "/business",
  component: Module,
  children: [
    {
      path: "/",
      name: "business",
      component: AssignEmployees,
      meta: {  }
    },
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
