<template>
<div>
    <!-- <div class="overlay" :class="{ show: isOpen }" @click="isOpen = !isOpen"></div> -->

    <div class="id-app-settings" :class="{ active: isOpen }">
        <a href="javascript:;" id="settingbutton" class="setting" @click="isOpen = !isOpen">
            <h5 class="mb-0">
                <svg data-slot="icon" width="40" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"></path>
                </svg>
            </h5>
        </a>
        <perfect-scrollbar class="sidbarchat p-3" tag="div" v-if="employee">
            <a class="btn-close" href="javascript:;" @click="isOpen = !isOpen">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </a>
            <div class="text-center mt-5">
                <img :src="qrCodeDataUrl" alt="..." class="img-fluid" v-if="qrCodeDataUrl" />
                <div v-else>

                    <svg data-slot="icon" width="100" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"></path>
                    </svg>
                    <p class="text-danger">QR GENERATION FAILED!</p>
                </div>

            </div>
            <hr />
            <div class="d-flex align-items-stretch mb-4">
                <div class="avatar mr-3">
                    <b-avatar :src="avatarUrl" size="6rem"></b-avatar>
                </div>
                <div class="header-text align-items-start">
                    <h4 class="mb-2">{{ employee ? employee.name : '' }}</h4>

                    <b-badge :variant="designationVariant(employee.ManpowerType.id, employee.ManpowerType.isManager)">{{ employee.ManpowerType.name || '' }}</b-badge>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <p class="text-muted">Date Of Joining</p>
                <p class="mb-1 mb-3">{{ formatDate(employee.joining_date) || '' }}</p>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <p class="text-muted">Blood Group</p>
                <p class="mb-1 mb-3">{{ employee.blood_group || 'N/A' }}</p>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <p class="text-muted">Contact Email</p>
                <p class="mb-1 mb-3">{{ employee.contact_email || 'N/A' }}</p>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <p class="text-muted">Contact Phone</p>
                <p class="mb-1 mb-3">{{ employee.contact_number || 'N/A' }}</p>
            </div>
            <div class="d-flex justify-content-around m-2">
                <b-button variant="info" class="mb-2 mr-2" @click="downloadIdCard">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>

                </b-button>
                <!-- <b-button variant="warning" class="mb-2 mr-2">
                    <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"></path>
</svg>
                </b-button> -->
                <b-button variant="dark" class="mb-2 mr-2" @click="$bvModal.show('qr-scanner-modal')" v-if="($store.state.auth.manpowerType && $store.state.auth.manpowerType.isManager)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize" data-v-02c2cbc4="">
                        <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                    </svg>
                </b-button>
                <!-- QR MODAL -->
                <b-modal id="qr-scanner-modal" title="QR SCANNER" hide-footer centered @hide="onModalClose" @show="onModalOpen" no-close-on-backdrop>

                    <qrcode-stream :camera="cameraType" :track="paintOutline" @decode="onDecode" @init="onInit" @error="onError" v-if="isCameraOn">
                        <div class="loader mx-auto" v-if="loading"></div>
                        <b-alert :show="isError" dismissible variant="light-danger" class="alert-arrow-right alert-icon-right mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12" y2="16"></line>
                            </svg>
                            <strong>Warning!</strong> {{ errorMsg }}
                        </b-alert>
                        <b-button variant="dark" class="mb-2 mr-2" @click="startFrontCamera" v-if="!loading && isCameraOn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-repeat" data-v-02c2cbc4="">
                                <polyline points="17 1 21 5 17 9"></polyline>
                                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                                <polyline points="7 23 3 19 7 15"></polyline>
                                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                            </svg>
                        </b-button>
                    </qrcode-stream>
                    
                    <div class="text-center" v-else>
                        <b-button variant="dark" class="mb-2 mr-2" @click="toggleCamera">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize" data-v-02c2cbc4="">
                                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                            </svg>
                        </b-button>
                    </div>

                    <b-card class="component-card_5" v-if="scannedEmployee">
                        <div class="d-flex">
                            <b-avatar :src="scannedEmpAvatarUrl" size="4rem"></b-avatar>
                            <div class="media-body ml-2">
                                <h4 class="card-user_name">{{ scannedEmployee && scannedEmployee.name || null}}</h4>
                                <p class="card-user_occupation">{{ scannedEmployee && scannedEmployee.ManpowerType.name || null }}</p>
                            </div>
                            <a href="javascript:;" v-b-tooltip.hover title="View">
                                <svg xmlns="http://www.w3.org/2000/svg" style="color: #2196f3" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye" data-v-02c2cbc4="">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </a>
                            <a href="javascript:;" v-b-tooltip.hover title="Edit" class="ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" style="color: #e2a03f" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit" data-v-02c2cbc4="">
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                            </a>
                        </div>

                        <employee-attendance-card v-if="currentEmpAssignment && other_attendance"
                        :initialCurrentEmpAssignment="currentEmpAssignment"
                        :initialOtherAttendance="other_attendance"
                        :onAttendanceMarked="handleAttendanceMarked"
                        ></employee-attendance-card>
                        

                    </b-card>
                </b-modal>

            </div>
        </perfect-scrollbar>
    </div>
</div>
</template>

<script>
import QRCode from 'qrcode'
import html2canvas from 'html2canvas';
import '@/assets/sass/components/cards/card.scss';
import {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
} from 'vue-qrcode-reader'

import EmployeeAttendanceCard from '@/components/module/staff/EmployeeAttendanceCard.vue';


export default {
    components: {
        QrcodeStream,
        EmployeeAttendanceCard
    },
    data() {
        return {
            asyncLoading: false,
            isOpen: null,
            qr_image: '/img/qr-code.png',
            qrCodeDataUrl: null,
            sa: null,
            loading: true,
            destroyed: false,
            isError: false,
            errorMsg: null,
            code: null,
            useFrontCamera: false,
            isCameraOn: true,
            currentTime: this.$moment().format('YYYY-MM-DD HH:mm:ss'), // Initial current time
            timer: null, // Placeholder for the interval
            scannedEmployee: null,
            scannedCustomer: null,
            scannedVendor: null,
            currentEmpAssignment: null,
            currentCustomerAssignments: null,
            other_attendance: {
                id: null,
                employeeId: null,
                assignmentId: null,
                contractDetailId: null,
                date: null,
                check_in_time: null,
                check_out_time: null,
                approved_by: null,
                status: null,
                notes: null,
            }
        };
    },

    async mounted() {

        this.isCameraOn = true
        this.sa = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 15000,
            timerProgressBar: true,
        });
    },
    watch: {
        employee(newEmployee, oldEmployee) {
            if (newEmployee && newEmployee.uuid) {
                const baseUrl = window.location.origin;
                let empUrl = `${baseUrl}/employees/view/${newEmployee.uuid}`;
                this.generateQRCode(empUrl);
            } else {
                console.log('QR NOT GENERATED');
            }
        },
    },
    computed: {
        cameraType() {
            if (this.useFrontCamera) {
                return 'front'
            } else {
                return 'auto'
            }
        },
        scannedEmpAvatarUrl() {
            if (this.scannedEmployee && this.scannedEmployee.EmployeeDocuments && this.scannedEmployee.EmployeeDocuments.length > 0) {
                let documents = this.scannedEmployee.EmployeeDocuments;
                return documents.find((doc) => doc.isProfilePic).storage_url;
            } else {
                return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
            }
        },
        employee() {
            return this.$store.state.employees.employee;
        },
        avatarUrl() {
            if (this.employee && this.employee.EmployeeDocuments && this.employee.EmployeeDocuments.length > 0) {
                let documents = this.employee.EmployeeDocuments;
                return documents.find((doc) => doc.isProfilePic).storage_url;
            } else {
                return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
            }
        },

    },
    created() {
        this.timer = setInterval(() => {
            this.currentTime = this.$moment().format('dddd MMM Do YYYY, h:mm:ss a');
        }, 1000); // Update time every second
    },
    destroyed() {
        this.isCameraOn = false,
        
        clearInterval(this.timer);
    },
    methods: {
        formatTime(time) {
            let timeToFormat = '2000-01-01 ' + time;
            return this.$moment(timeToFormat).format('LT');
        },
        generateQRCode(data) {

            QRCode.toDataURL(data)
                .then(dataUrl => {
                    this.qrCodeDataUrl = dataUrl
                })
                .catch(err => {
                    console.error('Error generating QR code:', err)
                })
        },
        designationVariant(id, manager) {
            if (id && manager) {
                if (id == 2) {
                    return 'info';
                } else if (id == 3) {
                    return 'secondary';
                } else if (id == 4) {
                    return 'warning';
                } else {
                    return 'primary';
                }
            } else {
                return 'dark';
            }
        },
        formatDate(date) {
            return this.$moment(date).format('MMM Do, YYYY');
        },
        formatPhoneNumber(phoneNumber) {
            if (!phoneNumber) return null;

            // Check if the phone number is already in the desired format
            const formattedPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
            if (formattedPattern.test(phoneNumber)) {
                return phoneNumber;
            }

            // Format the phone number if it's a string of digits
            const digitsPattern = /^(\d{3})(\d{3})(\d{4})$/;
            const match = phoneNumber.match(digitsPattern);
            return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
        },
        async downloadIdCard() {
            if (!this.qrCodeDataUrl || !this.employee || !this.employee.uuid) {
                this.sa.fire({
                    icon: 'error',
                    title: `Oops! Employee / QR Not Found!`,
                });
                return;
            }
            // Create an instance of EmployeeCard
            const response = await fetch('/id-card-template.html');
            const template = await response.text();
            let formattedDate = this.formatDate(this.employee.joining_date);
            const baseUrl = window.location.origin;
            let logoUrl = `${baseUrl}/img/final-logo.png`;
            let qrUrl = `${baseUrl}/img/qr-code.png`;
            const renderedTemplate = template
                .replace("{{EMPLOYEE_NAME}}", this.employee.name)
                .replace("{{EMPLOYEE_DESIGNATION}}", this.employee.ManpowerType.name)
                .replace("{{EMPLOYEE_EMAIL}}", this.employee.contact_email)
                .replace("{{EMPLOYEE_JOINING_DATE}}", formattedDate)
                .replace("{{EMPLOYEE_BLOOD_GROUP}}", this.employee.blood_group)
                .replace("{{EMPLOYEE_CONTACT_NUMBER}}", this.formatPhoneNumber(this.employee.contact_number))
                .replace("{{PROFILE}}", this.avatarUrl)
                .replace("{{QR}}", this.qrCodeDataUrl)
                .replace("{{LOGO}}", logoUrl)
            const container = document.createElement('div');
            // container.style.visibility = 'hidden';
            container.style.position = 'absolute';
            container.style.top = '-9999999px';
            container.innerHTML = renderedTemplate;
            document.body.appendChild(container);
            html2canvas(container, {
                useCORS: true,
                allowTaint: false,
            }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = 'id-card.png';
                link.href = imgData;
                link.click();
                window.location.reload();
            });
        },
        startFrontCamera() {
            // this.currentFacingMode = this.currentFacingMode === 'environment' ? 'user' : 'environment';
            // this.componentKey++;
            this.useFrontCamera = !this.useFrontCamera

        },
        onError(error) {
            console.log('err', error)

        },
        async onInit(promise) {
            // show loading indicator
            this.loading = true
            try {
                const {
                    capabilities
                } = await promise
                this.loading = false
                // successfully initialized
            } catch (error) {
                this.isError = true;
                this.errorMsg = error;
                if (error.name === 'NotAllowedError') {
                    // user denied camera access permisson
                    this.errorMsg = "user denied camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    // no suitable camera device installed
                    this.errorMsg = "no suitable camera device installed"
                } else if (error.name === 'NotSupportedError') {
                    // page is not served over HTTPS (or localhost)
                    this.errorMsg = "page is not served over HTTPS (or localhost)"
                } else if (error.name === 'NotReadableError') {
                    // maybe camera is already in use
                    this.errorMsg = "maybe camera is already in use"
                } else if (error.name === 'OverconstrainedError') {
                    // did you requested the front camera although there is none?
                    this.errorMsg = "did you requested the front camera although there is none?"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    // browser seems to be lacking features
                    this.errorMsg = "browser seems to be lacking features"
                }
            } finally {
                this.loading = false
            }
        },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

                ctx.strokeStyle = 'red'

                ctx.beginPath()
                ctx.moveTo(firstPoint.x, firstPoint.y)
                for (const {
                        x,
                        y
                    } of otherPoints) {
                    ctx.lineTo(x, y)
                }
                ctx.lineTo(firstPoint.x, firstPoint.y)
                ctx.closePath()
                ctx.stroke()
            }
        },
        async onDecode(decodedString) {
            const isUpi = this.isUPIUrl(decodedString)
            const qrType = this.getQrType(decodedString)
            if (!isUpi && qrType && qrType.type && qrType.id) {
                if (qrType.type === 'employees') await this.getEmployeeWithAssignment(qrType.id)
                this.code = `Type: ${qrType.type} | ID: ${qrType.id}`
                this.isCameraOn = false
            } else {
                this.code = 'Invalid QR'
            }

        },
        getQrType(url) {
            // Parse the URL
            const parsedUrl = new URL(url);

            // Split the pathname into segments
            const pathSegments = parsedUrl.pathname.split('/').filter(segment => segment);

            // Initialize the result object with null values
            let result = {
                type: null,
                id: null
            };

            // Check for 'employees', 'customers', or 'vendors' in the path segments
            const types = ['employees', 'customers', 'vendors'];
            types.forEach(type => {
                if (pathSegments.includes(type)) {
                    result.type = type;
                }
            });

            // Regular expression to check for UUID
            const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

            // Check if the last segment is a valid UUID
            const lastSegment = pathSegments[pathSegments.length - 1];
            if (uuidRegex.test(lastSegment)) {
                result.id = lastSegment;
            }

            return result;
        },
        isUPIUrl(url) {
            return url.startsWith('upi://');
        },
        extractUPIID(qrData) {
            try {
                const urlObj = new URL(qrData);
                const urlParams1 = new URLSearchParams(urlObj.search);
                const paValue = urlParams1.get('pa');
                return paValue || null;
            } catch (error) {
                console.error('Error extracting UPI ID:', error);
                return null;
            }
        },
        onModalClose(bvModalEvent) {
            this.code = null
            this.errorMsg = null
            this.scannedEmployee = null
            this.scannedCustomer = null
            this.scannedVendor = null
            this.currentCustomerAssignments = null
            this.currentEmpAssignment = null
            this.other_attendance = {
                id: null,
                employeeId: null,
                assignmentId: null,
                contractDetailId: null,
                date: null,
                check_in_time: null,
                check_out_time: null,
                approved_by: null,
                status: null,
                notes: null,
            }
            this.isCameraOn = false
            this.$store.commit('employees/setScannedEmployee', null)
            this.$store.commit('employees/setAddedEmployee', null)
            this.$store.commit('employees/setNowEmployeeAssignment', null)
            this.$store.commit('employees/setEmployeeOtherAttendances', [])
            this.$store.commit('employees/setAddedEmployeeOtherAttendance', null)
            this.$store.commit('employees/setEmployeeOtherAttendancesToday', [])
        },
        onModalOpen() {
            this.isCameraOn = true
        },
        toggleCamera() {
            this.code = null
            this.errorMsg = null
            this.isCameraOn = !this.isCameraOn
            this.currentEmpAssignment = null;
            this.scannedEmployee= null,
            this.scannedCustomer= null,
            this.scannedVendor= null,
            this.currentEmpAssignment= null,
            this.currentCustomerAssignments=null,
            this.other_attendance= {
                id: null,
                employeeId: null,
                assignmentId: null,
                contractDetailId: null,
                date: null,
                check_in_time: null,
                check_out_time: null,
                approved_by: null,
                status: null,
                notes: null,
            }
            
        },
        //GET SCANNED EMP
        async getEmployeeWithAssignment(id) {
            await this.$store.dispatch('employees/getEmployeeByScannedId', id)
            this.scannedEmployee = await this.$store.state.employees.scannedEmployee;

            let finalEmpAssignment = null
            let finalDate = this.$moment();

            let currentDate = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            let previousDate = this.$moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');

            let data = {
                id,
                currentDate
            }
            await this.$store.dispatch('employees/getCurrentTimeEmployeeAssignmentByUUID', data);
            const empAssignment = await this.$store.state.employees.nowEmployeeAssignment;
            if (empAssignment) {
                const actualDate = this.getCurrentDate(empAssignment.ContractDetail.Shift.startTime, empAssignment.ContractDetail.Shift.endTime);
                const currentDateOnly = this.$moment(currentDate).startOf('day');
                const actualDateOnly = this.$moment(actualDate).startOf('day');
                if (!currentDateOnly.isSame(actualDateOnly)) {
                    data = {
                        id,
                        currentDate: previousDate
                    }
                    await this.$store.dispatch('employees/getCurrentTimeEmployeeAssignmentByUUID', data);
                    const empAssignmentprevious = await this.$store.state.employees.nowEmployeeAssignment;
                    if (empAssignmentprevious) {
                        finalDate = this.$moment.isMoment(actualDate) 
                                    ? actualDate.format('YYYY-MM-DD')
                                    : this.$moment(actualDate).format('YYYY-MM-DD');
                        finalEmpAssignment = {
                            ...empAssignmentprevious
                        }
                    }
                }else{
                    finalDate = this.$moment.isMoment(currentDate) 
                     ? currentDate.format('YYYY-MM-DD')
                     : this.$moment(currentDate).format('YYYY-MM-DD');
                    finalEmpAssignment = {
                        ...empAssignment
                    }
                }
            }

            if (finalEmpAssignment) {
                
                await this.$store.dispatch('employees/getEmployeeOtherAttendances')
                const otherAttedances = this.$store.state.employees.employee_other_attendances
                const currentAttendance = otherAttedances.find((item) => {
                    return item.date === finalDate &&
                        item.employeeId === finalEmpAssignment.employeeId &&
                        item.contractDetailId === finalEmpAssignment.contractDetailId &&
                        item.assignmentId === finalEmpAssignment.id;
                });
                if (currentAttendance && currentAttendance != undefined) {
                    this.other_attendance = {
                        ...currentAttendance
                    }
                }
                const customerName = finalEmpAssignment.ContractDetail.Contract.Customer.name || null;
                const shiftName = finalEmpAssignment.ContractDetail.Shift.name || null;
                const shiftStartTime = finalEmpAssignment.ContractDetail.Shift.startTime || null;
                const shiftEndTime = finalEmpAssignment.ContractDetail.Shift.endTime || null;
                const customerAddress = finalEmpAssignment.ContractDetail.Contract.Customer.address || null;
                const customerCity = finalEmpAssignment.ContractDetail.Contract.Customer.City.name || null;
                const customerState = finalEmpAssignment.ContractDetail.Contract.Customer.State.name || null;
                const customerPincode = finalEmpAssignment.ContractDetail.Contract.Customer.Pincode.pincode || null;
                const rate = finalEmpAssignment.RateCard.rate || null
                const duration = finalEmpAssignment.RateCard.durationType || null
                const earnings = this.calculateEarnings(rate, duration, shiftStartTime, shiftEndTime);
                this.currentEmpAssignment = {
                    customerName,
                    shiftName,
                    shiftStartTime,
                    shiftEndTime,
                    customerAddress,
                    customerCity,
                    customerState,
                    customerPincode,
                    earnings,
                    assignmentId: finalEmpAssignment.id,
                    employeeId: finalEmpAssignment.employeeId,
                    shiftId: finalEmpAssignment.ContractDetail.shiftId,
                    contractDetailId: finalEmpAssignment.contractDetailId,
                }

            }
        },

        isNightShift(startTime, endTime) {
            // Parse the start and end times
            const start = this.$moment(startTime, 'HH:mm');
            const end = this.$moment(endTime, 'HH:mm');


            // If start time is greater than or equal to end time, the shift spans over midnight
            if (start.isSameOrAfter(end)) {
                return true;
            } else {
                return false;
            }
        },

        calculateEarnings(rate, duration, shiftStartTime, shiftEndTime) {
            let earnings = 0;
            const numericRate = parseFloat(rate);
            if (isNaN(numericRate)) {
                return 0;
            }

            try {
                switch (duration) {
                    case 'hourly': {
                        const start = this.$moment(shiftStartTime, 'HH:mm:ss');
                        const end = this.$moment(shiftEndTime, 'HH:mm:ss');
                        if (end.isBefore(start)) {
                            end.add(1, 'day');
                        }
                        const hours = end.diff(start, 'hours', true);
                        earnings = numericRate * hours;
                        break;
                    }

                    case 'daily':
                        earnings = numericRate;
                        break;

                    case 'weekly':
                        earnings = numericRate / 7;
                        break;

                    case 'monthly': {
                        const daysInMonth = this.$moment().daysInMonth();
                        earnings = numericRate / daysInMonth;
                        break;
                    }

                    case 'yearly': {
                        const year = this.$moment().year();
                        const daysInYear = this.$moment([year]).isLeapYear() ? 366 : 365;
                        earnings = numericRate / daysInYear;
                        break;
                    }

                    default:
                        break;
                }
            } catch (error) {
                console.error('Error calculating earnings:', error);
            }
            return earnings;
        },
        getCurrentDate(assignmentShiftStartTime, assignmentShiftEndTIme) {
            const currentDate = this.$moment(); // Example current time
            // const currentDate = this.$moment('2023-12-23 23:55', 'YYYY-MM-DD HH:mm');

            let newDate = currentDate.clone();
            const nightShift = this.isNightShift(assignmentShiftStartTime, assignmentShiftEndTIme)
            if (nightShift) {
                const startTime = this.$moment(assignmentShiftStartTime, 'HH:mm');
                const endTime = this.$moment(assignmentShiftEndTIme, 'HH:mm');

                if (currentDate.hour() >= 0 && currentDate.hour() < startTime.hour()) {
                    // Current time is after midnight but before the start of the shift
                    newDate = startTime.clone().subtract(1, 'day');
                } else {
                    // Current time is before midnight or after the start of the shift
                    endTime.add(1, 'day');
                }

                if (currentDate.isAfter(startTime) && currentDate.isBefore(endTime)) {
                    // Current time falls within the shift period
                    return newDate;
                }

            }
            return newDate;
        },
        handleAttendanceMarked(otherAttendance){
            this.other_attendance = {...otherAttendance}
        }

    },
};
</script>

<style scoped>

.floating-icon-idcard {
    background: #4361ee;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    font-size: 25px;
    border-radius: 50% !important;
    padding: 7px;
}
</style>
