const Module = () => import("./Module.vue");
const ViewEmployees = () => import("./views/ViewEmployees.vue");
const AddEmployee = () => import("./views/AddEmployee.vue");
const EditEmployee = () => import("./views/EditEmployee.vue");
const ViewEmployee = () => import("./views/ViewEmployee.vue");
const StaffRole = () => import("./views/StaffRole.vue");
const Assignments = () => import("./views/Assignments.vue");
const Attendances = () => import("./views/Attendances.vue");
const LeaveRequests = () => import("./views/Requests/LeaveRequests.vue");
const AdvanceRequests = () => import("./views/Requests/AdvanceRequests.vue");
const InventoryRequest = () => import("./views/Requests/InventoryRequest.vue");
const TransferRequest = () => import("./views/Requests/TransferRequest.vue");
const EmployeeInventories = () => import("./views/EmployeeInventories.vue");
import Store from '../../store'; // Adjust the path as necessary to import your store

const moduleRoute = {
  path: "/employees",
  component: Module,
  children: [
    {
      path: "/",
      name: "employees",
      component: ViewEmployees,
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_employees')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
      
    },
    {
      path: "add",
      name: "add_employees",
      component: AddEmployee,
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_employees_add')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "edit/:id", // Updated to include the parameter 'id'
      name: "edit_employees",
      component: EditEmployee, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_employees_add')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "view/:id", // Updated to include the parameter 'id'
      name: "view_employee",
      component: ViewEmployee, // Updated to use the EditEmployee component
      meta: { layout: 'public' },

    },
    {
      path: "roles",
      name: "staff_roles",
      component: StaffRole,
      meta: {},
      beforeEnter: (to, from, next) => {
        const user = Store.state.auth.userData;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || user.user_type === 'admin') {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "assignments", // Updated to include the parameter 'id' is contract detail Id
      name: "employee_assignments",
      component: Assignments, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_assignments')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }

    },
    {
      path: "attendances", // Updated to include the parameter 'id' is contract detail Id
      name: "employee_attendances",
      component: Attendances, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_attendances')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }

    },
    {
      path: "inventories", // Updated to include the parameter 'id' is contract detail Id
      name: "employee_inventories",
      component: EmployeeInventories, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_employee_inventories')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "requests/leave", // Updated to include the parameter 'id' is contract detail Id
      name: "leave-application",
      component: LeaveRequests, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_requests_leave')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "requests/advance", // Updated to include the parameter 'id' is contract detail Id
      name: "advance-request",
      component: AdvanceRequests, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_requests_advance')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "requests/inventory", // Updated to include the parameter 'id' is contract detail Id
      name: "inventory-request",
      component: InventoryRequest, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_requests_inventory')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "requests/transfer", // Updated to include the parameter 'id' is contract detail Id
      name: "transfer-request",
      component: TransferRequest, // Updated to use the EditEmployee component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_requests_transfer')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
