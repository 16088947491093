<template>
<div class="widget widget-wallet-balance">
    <div class="widget-heading d-block">
        <div class="wallet-usr-info">
            <div class="usr-name">
                <span><img :src="avatarUrl" alt="admin-profile" class="img-fluid" /> {{ employee.name || null }}</span>
            </div>
            <div class="add" @click="refreshWallet">
                <!-- <div class="loader mx-auto" v-if="asyncLoading"></div> -->
                <svg v-if="asyncLoading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin mr-2">
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
                <span v-else><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw" data-v-02c2cbc4="">
                        <polyline points="1 4 1 10 7 10"></polyline>
                        <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                    </svg></span>
            </div>
        </div>
        <div class="wallet-balance">
            <p>Current Month Income</p>
            <svg v-if="asyncLoading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin mr-2">
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>

            <h5 v-else><span class="w-currency">&#8377;</span>{{ totalMonthlyIncome.amount || 0}}</h5>
        </div>
    </div>

    <div class="widget-amount">
        <div class="w-a-info funds-received mr-3">
            <span v-if="incomes && incomes.length > 0">{{ formatShortDate(incomes[0].date) || 'Recent Earning' }}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up text-success float-right">
                    <polyline points="18 15 12 9 6 15"></polyline>
                </svg></span>
            <span v-else>Earnings
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up text-success float-right">
                <polyline points="18 15 12 9 6 15"></polyline>
            </svg></span>
            <p v-if="asyncLoading"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin mr-2">
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            </p>
            <p v-else>+ &#8377; {{ (incomes[0] && incomes[0].earned) ? Math.round(incomes[0].earned) : 0 || '0' }}</p>
        </div>
        <div class="w-a-info funds-spent">
            <span>Advance
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down text-danger float-right">
                    <polyline points="6 9 12 15 18 9"></polyline>
                </svg></span>
            <p v-if="asyncLoading"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin mr-2">
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            </p>
            <p v-else>&#8377; 
                {{ getCurrentMonthAdvance && Math.round(getCurrentMonthAdvance.sum) }}
            </p>
        </div>
    </div>

    <div class="widget-content">
        <div class="bills-stats mb-4" v-if="transaction && !transaction.id">
            <p>Advance</p><span>NA</span>
        </div>
        <div class="bills-stats mb-4" v-else>
            <p>Advance - &#8377; {{ transaction.amount || 0 }}</p><span>{{ transaction.status }}</span>
        </div>
        <div class="invoice-list">
            <div class="inv-detail mb-4" v-if="asyncLoading">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader spin mr-2">
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            </div>

            <div class="inv-detail mb-4" v-else>
                <div class="info-detail-1">
                    <p>{{ monthlyIncomesSum[0].month || 'NA' }}</p>
                    <p><span class="w-currency">&#8377;</span> <span class="bill-amount">{{ monthlyIncomesSum[0].sum || 0 }}</span></p>
                </div>
                <div class="info-detail-2" v-if="monthlyIncomesSum && monthlyIncomesSum.length > 1">
                    <p>{{ monthlyIncomesSum[1].month || 'NA' }}</p>
                    <p><span class="w-currency">&#8377;</span> <span class="bill-amount">{{ monthlyIncomesSum[1].sum || 0 }}</span></p>
                </div>
            </div>
            <div class="inv-action">
                <b-button v-if="transaction && transaction.status === 'draft' && !transaction.id" tag="a" variant="outline-success" class="pay-now" @click="openRequestModal">Request Advance [ MAX - &#8377; {{ Math.round(maxAdvance) || 0 }} ]</b-button>
                <p v-if="transaction && transaction.status === 'pending'">Advance Request In Process<br/>{{ formatShortDate(transaction.request_date) }} | {{ transaction.amount }} | {{ transaction.status }}</p>
                <b-modal id="advanceRequestModal" size="sm" title="Request Advance" hide-footer centered>
                    <b-input-group class="mb-4">
                        <b-input type="number" :placeholder="maxAdvance.toString() || ''" v-model="transaction.amount" @blur="validateAmount" :state="isValidAmount">
                        </b-input>
                        <b-form-invalid-feedback v-if="!isValidAmount">
                            Amount should be between 100 and {{ maxAdvance }}
                        </b-form-invalid-feedback>
                        <b-input-group-append>
                            <b-button variant="primary" @click="requestAdvance"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square" data-v-02c2cbc4="">
                                    <polyline points="9 11 12 14 22 4"></polyline>
                                    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                </svg>
                                Request</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-modal>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import '@/assets/sass/widgets/widgets.scss';

export default {
    data() {
        return {
            incomes: [],
            asyncLoading: false,
            transaction: {
                employeeId: null,
                request_date: this.$moment().format('YYYY-MM-DD'),
                payment_for: 'Advance',
                payment_type: 'credit',
                status: 'draft',
                amount: null,
            },
            sa:null
        }
    },
    async mounted() {
        this.sa = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
      });
        await this.fetchIncomes();
        await this.fetchTransactions();
    },
    computed: {
        isValidAmount() {
            const amount = Number(this.transaction.amount);
            return amount >= 100 && amount <= this.maxAdvance;
        },
        employee() {
            return this.$store.state.employees.employee
        },
        transactions() {
            return this.$store.state.business.transactions
        },
        avatarUrl() {
            if (this.employee && this.employee.EmployeeDocuments && this.employee.EmployeeDocuments.length > 0) {
                let documents = this.employee.EmployeeDocuments
                return documents.find((doc) => doc.isProfilePic).storage_url;
            } else {
                return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png'
            }
        },
        totalMonthlyIncome() {
            const currentMonth = this.$moment().month();
            const currentYear = this.$moment().year();

            const monthlyIncomes = this.incomes.filter(income => {
                const incomeMonth = this.$moment(income.date).month();
                const incomeYear = this.$moment(income.date).year();
                return incomeMonth === currentMonth && incomeYear === currentYear;
            });

            const total = monthlyIncomes.reduce((sum, income) => {
                return sum + parseFloat(income.earned);
            }, 0);

            return {
                month: this.$moment().format('MMMM YYYY'), // Format: 'January 2023'
                amount: Math.round(total)
            };
        },
        monthlyIncomesSum() {
            const currentYear = this.$moment().year();
            const currentMonth = this.$moment().month(); // 0 to 11 for Jan to Dec
            const startMonth = currentMonth === 0 ? 0 : currentMonth - 1; // Start from the previous month or January

            // Initialize sums for each month from startMonth to January
            const monthlySums = {};
            for (let month = startMonth; month >= 0; month--) {
                const monthKey = this.$moment([currentYear, month]).format('MMMM YYYY'); // Format: 'January 2023'
                monthlySums[monthKey] = 0;
            }

            // Aggregate earnings for each month
            this.incomes.forEach(income => {
                const incomeMonthYear = this.$moment(income.date).format('MMMM YYYY'); // Format: 'January 2023'
                if (Object.prototype.hasOwnProperty.call(monthlySums, incomeMonthYear)) {
                    monthlySums[incomeMonthYear] += parseFloat(income.earned);
                }
            });

            // Convert the monthly sums object into an array
            const monthlySumArray = Object.keys(monthlySums).map(month => ({
                month,
                sum: Math.round(monthlySums[month])
            }));

            return monthlySumArray;
        },
        maxAdvance() {
            return this.totalMonthlyIncome.amount * 20 / 100
        },
        getCurrentMonthAdvance(){
            // Get the current date and set the start and end of the month using Moment.js
            const currentMonthStart = this.$moment().startOf('month');
            const currentMonthEnd = this.$moment().endOf('month');

            // Filter the transactions based on the conditions
            const filteredTransactions = this.transactions.filter(transaction => {
            if(!transaction.payment_date) return false;
            const transactionDate = this.$moment(transaction.payment_date);
            if(transaction.payment_for === 'Advance' && (transaction.status === 'pending' || transaction.status === 'draft') && transactionDate.isSameOrAfter(currentMonthStart) &&
            transactionDate.isSameOrBefore(currentMonthEnd) && transaction.employeeId === this.employee.uuid){
                this.transaction = {...transaction}
            }
            return transaction.employeeId === this.employee.uuid &&
                    transaction.payment_for === 'Advance' &&
                    transactionDate.isSameOrAfter(currentMonthStart) &&
                    transactionDate.isSameOrBefore(currentMonthEnd);
            });

            // Calculate the sum of amounts for the filtered transactions
            const sum = filteredTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0);

            // Return the object with status, sum, and records properties
            return { 
            sum, 
            records: filteredTransactions 
            };
        }
    },
    methods: {
        validateAmount() {
            if (!this.isValidAmount) {
                this.transaction.amount = null // Reset the amount if it's not valid
                // Optionally, you can show an error message or handle the invalid input as needed
            }
        },
        formatShortDate(date) {
            const curentDate = this.$moment(date).fromNow()
            return curentDate

        },
        async refreshWallet() {
            await this.fetchIncomes();
        },
        async fetchIncomes() {
            this.asyncLoading = true
            if (this.employee.uuid) {
                this.$set(this.transaction, 'employeeId', this.employee.uuid)
                await this.$store.dispatch('employees/getIncomes', this.employee.uuid)
                let incomes = [...this.$store.state.employees.incomes]; // Clone the array from Vuex state
                // filter incomes where status is 'approved' or status is 'present'
                incomes = incomes.filter(income => income.status === 'approved' || income.status === 'present');
                incomes.sort((a, b) => {
                    // Convert date strings to Date objects for comparison
                    const dateA = this.$moment(a.date, 'YYYY-MM-DD').toDate();
                    const dateB = this.$moment(b.date, 'YYYY-MM-DD').toDate();

                    return dateB - dateA; // Sort in descending order
                });
                this.incomes = [...incomes];
            }
            this.asyncLoading = false
        },
        async fetchTransactions() {
            this.asyncLoading = true
                await this.$store.dispatch('business/getTransactions')
               
            this.asyncLoading = false
        },
        async requestAdvance(){
            if(!this.isValidAmount){
                this.sa.fire({
                    icon: 'error',
                    title: `Oops! Invalid Advance Request!`
                });
                return;
            }

            if(!this.transaction.id){
                if(!this.transaction.employeeId && this.employee.uuid){
                    this.$set(this.transaction, 'employeeId', this.employee.uuid)
                    
                }
                if(this.transaction.status === 'draft'){
                    this.$set(this.transaction, 'status', 'pending')
                }
                
                await this.$store.dispatch('business/addTransaction', this.transaction)
                let transaction = this.$store.state.business.addedTransaction;
                this.transaction = {...transaction}
                if(this.transaction.id){
                    this.sa.fire({
                        icon: 'success',
                        title: `Your Request Is In Process...`
                    });
                    this.$bvModal.hide('advanceRequestModal')
                }
            }
        },
        openRequestModal(){
            if(this.maxAdvance < 100) {
                this.sa.fire({
                    icon: 'error',
                    title: `Oops! Invalid Advance Request!`
                });
                return;
            }
            this.$bvModal.show('advanceRequestModal')
        }
    }
}
</script>
