import * as types from "./mutation-types";

export default {
    RESET_STATE(state) {
        Object.assign(state, {
            products:[],
            product_categories:[],
        });
    },
    
    setProducts(state, payload) {
        state.products = payload;
    },
    setProductCategories(state, payload) {
        state.product_categories = payload;
    },
    setAddedVariants(state, payload) {
        state.addedVariants = payload;
    },
    setEmployeeInventories(state, payload) {
        state.employee_inventories = payload;
    },
};
