import * as types from "./mutation-types";

export default {
    RESET_STATE(state) {
        Object.assign(state, {
            fcmToken: null,
            vendors: [],
            managers: [],
            vendor: null,
            onlineVendors: [],
            scannedVendor: null,
            addedVendor: null,
            addedDocument: null,
            addedDocuments: [],
            vendor_assignments: [],
            addedVendorAssignments: [],
            nowVendorAssignment: null,
            vendor_other_attendances: [],
            vendor_other_attendances_today: [],
            addedVendorOtherAttendance: null,
            incomes: [],
            vendor_requests: [],
        });
    },
    
    setVendors(state, payload) {
        state.vendors = payload;
    },
    setFcmToken(state, token) {
        state.fcmToken = token;
    },
    setAddedVendor(state, payload) {
        state.addedVendor = payload;
    },
    setAddedDocument(state, payload) {
        state.addedDocument = payload;
    },
    setAddedDocuments(state, payload) {
        state.addedDocuments = payload;
    },
    setVendor(state, payload) {
        state.vendor = payload;
    },
    
    setScannedVendor(state, payload) {
        state.scannedVendor = payload
    },
    
};
