import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';


Vue.use(Vuex);
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL :  process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL :  process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL :  process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL

export default new Vuex.Store({
    state: {
        
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' }
        ],
        states: [],
        cities: [],
        pincodes: [],
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
        //Get Locations
        SET_STATES(state, states) {
        state.states = states;
        if(states.length > 0){
          localStorage.setItem('states', JSON.stringify(states));
        }
        
        },
        SET_CITIES(state, cities) {
        state.cities = cities;
        if(cities.length > 0){
          localStorage.setItem('cities', JSON.stringify(cities));
        }
        
        },
        SET_PINCODES(state, pincodes) {
        state.pincodes = pincodes;
        if(pincodes.length > 0){
          localStorage.setItem('pincodes', JSON.stringify(pincodes));
        }
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        }
    },
    actions: {
        async fetchStates({ commit }) {
            try {
              const localStates = localStorage.getItem('states');
              if (localStates) {
                commit('SET_STATES', JSON.parse(localStates));
              } else {
                const response = await axios.get(`${API_MISC_URL}/locations/states`);
                
                commit('SET_STATES', response.data);
              }
            } catch (error) {
              console.error('Error fetching states:', error);
            }
          },
          async fetchCities({ commit }) {
            try {
              const localCities = localStorage.getItem('cities');
              if (localCities) {
                commit('SET_CITIES', JSON.parse(localCities));
              } else {
                // Fetch cities from API
                const response = await axios.get(`${API_MISC_URL}/locations/cities`);
                commit('SET_CITIES', response.data);
              }
            } catch (error) {
              console.error('Error fetching cities:', error);
            }
          },
          async fetchPincodes({ commit }) {
            try {
              const localPincodes = localStorage.getItem('pincodes');
              if (localPincodes) {
                commit('SET_PINCODES', JSON.parse(localPincodes));
              } else {
                // Fetch pincodes from API
                const response = await axios.get(`${API_MISC_URL}/locations/pincodes`);
                commit('SET_PINCODES', response.data);
              }
            } catch (error) {
              console.error('Error fetching pincodes:', error);
            }
          },
    },
    modules: {}
});
