import axios from 'axios';
import moment from 'moment';
import { encryptData, decryptData } from '../../../utils/encryption';
import io from 'socket.io-client';
import messaging from '@/firebaseInit'; // Import the messaging instance
import { getToken } from "firebase/messaging";

const SOCKET_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_REALTIME_URL// Replace this with your Node.js API base URL

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL : process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL : process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL


export default {
  //EMPLOYEES
  async getVendors({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/vendors`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const vendors = response.data.vendors;
      if (vendors) {
        commit('setVendors', vendors);
      } else {
        throw new Error('Cannot Retreive Vendors'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async getVendorsViewById({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/vendor_view/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const vendor_view = response.data.vendor_view;
      if (vendor_view) {
        return vendor_view
      } else {
        throw new Error('Cannot Retreive Vendors Requests'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async searchVendorsByName({ commit }, name) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/getbyname`, {
        params: { name },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      commit('setVendors', response.data.vendors);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async addVendor({ dispatch, commit }, vendor) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/vendors`, vendor, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedVendor', response.data.vendor);
        await dispatch('getVendors');
        return response.data.vendor
      } else {
        throw new Error('Cannot Add Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateVendor({ dispatch, commit }, vendor) {

    try {
      const response = await axios.put(`${API_SERVICE_URL}/vendors/${vendor.uuid}`, vendor, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedVendor', response.data.vendor);
        await dispatch('getVendors');
      } else {
        throw new Error('Cannot Update Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getVendorByAuth({ commit, dispatch }, user_id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setVendor', response.data.vendor);
        commit('auth/setManpowerType', response.data.vendor.ManpowerType ? response.data.vendor.ManpowerType : null, { root: true });

        // Establish Socket.IO connection after successful vendor fetch
        // const token = localStorage.getItem('token');
        dispatch('connectToSocket');
      } else {
        throw new Error('Cannot Get Auth Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getVendorByUserId({ commit, dispatch }, user_id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.vendor
      } else {
        throw new Error('Cannot Get Vendor By User ID');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getVendor({ commit }, user_id) {
    console.log('GET EMP BY ID - ', user_id)
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/userId/${user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setAddedVendor', response.data.vendor);
      } else {
        throw new Error('Cannot Get Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async getVendorById({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.vendor;
      } else {
        throw new Error('Cannot Get Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async getVendorByScannedId({ commit }, uuid) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/vendors/${uuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        commit('setScannedVendor', response.data.vendor);
      } else {
        throw new Error('Cannot Get Vendor');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  //EMP Document
  async addVendorDocument({ dispatch, commit }, vendorDocument) {
    if (vendorDocument['vendorId'] != null || vendorDocument['vendorId'] != undefined || vendorDocument['vendorId'] != '') {
      try {
        const response = await axios.post(`${API_SERVICE_URL}/vendors/documents`, vendorDocument, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.status) {
          commit('setAddedDocument', response.data.vendor_document);
          await dispatch('getVendors');
        } else {
          throw new Error('Cannot ADD Vendor Document');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    } else {
      throw new Error(`Error - Vendor Id Not Found`);
    }

  },
  async updateVendorDocument({ dispatch, commit }, vendorDocument) {
    if (vendorDocument['vendorId'] != null || vendorDocument['vendorId'] != undefined || vendorDocument['vendorId'] != '') {
      let foundIdValue = null;
      for (const [key, value] of vendorDocument.entries()) {
        if (key === 'id') {
          foundIdValue = value;
          break; // Stop the loop once the 'id' value is found
        }
      }
      try {
        const response = await axios.put(`${API_SERVICE_URL}/vendors/documents/${foundIdValue}`, vendorDocument, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.status) {
          commit('setAddedDocument', response.data.vendor_document);
          await dispatch('getVendors');
        } else {
          throw new Error('Cannot Update Vendor Document');
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';

        // Check if the error is a response from the server with structured data
        if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
            // If there are multiple validation errors, concatenate their messages
            errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
            // Use the main error message if available
            errorMessage = error.response.data.message || errorMessage;
          }
        } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
        } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
        }

        throw new Error(errorMessage);
      }
    }
  },

  async deleteVendorDocument({ dispatch, commit }, id) {

    try {
      const response = await axios.delete(`${API_SERVICE_URL}/vendors/documents/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        console.log('DELTED')
      } else {
        throw new Error('Cannot Delete Vendor Document');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async addVendorDocuments({ dispatch, commit }, vendorDocuments) {

    try {
      let addedDocuments = []
      for (const document of vendorDocuments) {
        const formData = new FormData();
        // Assuming 'document' is a file object or similar

        for (let key in document) {
          if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
          }
        }
        // Append 'selected_file' separately if needed
        if (document.selected_file) {
          formData.append('file', document.selected_file);
        }
        if (document.vendorId) {
          const response = await axios.post(`${API_SERVICE_URL}/vendors/documents`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.vendor_document);
            addedDocuments.push(response.data.vendor_document)
            console.log(' Doc Added- ', response.data.vendor_document)
          } else {
            throw new Error('Cannot ADD Vendor Document');
          }
        }


      }
      commit('setAddedDocuments', addedDocuments);
      await dispatch('getVendors');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async updateVendorDocuments({ dispatch, commit }, vendorDocuments) {

    try {
      let addedDocuments = []
      for (const document of vendorDocuments) {
        const formData = new FormData();
        // Assuming 'document' is a file object or similar

        for (let key in document) {
          if (key !== 'imageUrl' && key !== 'selected_file' && document[key] != null) {
            formData.append(key, document[key]);
          }
        }
        // Append 'selected_file' separately if needed
        if (document.selected_file) {
          formData.append('file', document.selected_file);
        }
        if (document.vendorId) {
          const response = await axios.put(`${API_SERVICE_URL}/vendors/documents/${document['id']}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            }
          });

          if (response.data.status) {
            // commit('setAddedDocument', response.data.vendor_document);
            addedDocuments.push(response.data.vendor_document)
            console.log(' Doc Updated- ', response.data.vendor_document)
          } else {
            throw new Error('Cannot Update Vendor Document');
          }
        }

      }
      commit('setAddedDocuments', addedDocuments);
      await dispatch('getVendors');
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

};
