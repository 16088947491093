<template>
<div class="widget widget-dailysales" v-if="mostRecentAssignment">
    <div class="widget-heading">
        <div>
            <h5>Upcoming Assignment</h5>
            <span class="sub-title" v-if="mostRecentAssignment">{{ mostRecentAssignment && formatShortDate(mostRecentAssignment.assignmentDateTime) }}</span>
            
        </div>
        <div class="w-icon text-warning bg-warning">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
            </svg>
        </div>
    </div>
    
    <div class="widget-content text-white" v-if="mostRecentAssignment">
        <div class="d-flex justify-content-center flex-column m-2">
            <p class="text-center text-muted">{{currentTime}}</p>
        </div>
        <div class="d-flex">
            <b-avatar :src="customerAvatar" size="4rem"></b-avatar>
            <div class="ml-2 d-flex align-items-center">
                <h5 class="card-user_name">{{ mostRecentAssignment && mostRecentAssignment.customer_name || null}}</h5>
            </div>
        </div>
        <div class="ml-2 d-flex align-items-center">
                <p class="card-user_name">{{ getCustomerAddress() }}</p>
            </div>
        <div class="d-flex flex-row justify-content-between" v-if="mostRecentAssignment && !asyncLoading">
            <div class="d-flex flex-column">
                <p class="text-muted mt-2">Date</p>
                <p class="mb-1">{{ formatDate(mostRecentAssignment.assignmentDateTime) }}</p>
                <!-- <p class="mb-1">Income - <span>&#8377;</span> {{ Math.round(activeAssignment.earnings) }}</p> -->
            </div>
            <div class="d-flex flex-column">
                <p class="text-muted mt-2">Shift Time</p>
                <p class="mb-1">{{ formatTime(mostRecentAssignment.shiftStartTime) }} - {{ formatTime(mostRecentAssignment.shiftEndTime) }}</p>
                <!-- <p class="mb-1">Income - <span>&#8377;</span> {{ Math.round(activeAssignment.earnings) }}</p> -->
            </div>
            
        </div>
    </div>
    <div class="widget-content text-white" v-if="mostRecentAssignment">
        <div class="d-flex flex-row justify-content-around">
            <div class="d-flex flex-column">
                <p class="sv-time"><span>{{ timeDifference.days }}</span></p>
                <p class="text-center"><span>days</span></p>
            </div>
            <div class="d-flex flex-column">
                <p class="sv-time"><span>{{ timeDifference.hours }}</span></p>
                <p class="text-center"><span>hours</span></p>
            </div>
            <div class="d-flex flex-column">
                <p class="sv-time"><span>{{ timeDifference.minutes }}</span></p>
                <p class="text-center"><span>minutes</span></p>
            </div>
            <div class="d-flex flex-column">
                <p class="sv-time"><span>{{ timeDifference.seconds }}</span></p>
                <p class="text-center"><span>seconds</span></p>
            </div>
        </div>
    </div>
</div>
<div class="widget" v-else>

<p class="sub-title text-center p-4"> No Upcoming Assignments </p>

</div>
</template>

<style scoped>
p.sv-time {
    margin: 0 auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 0;
    background: #4361ee;
    color: #eaf1ff;
    font-weight: 700;
    font-size: 18px;
    z-index: 7;
    position: relative;
}

p.sv-time span {
    align-self: center;
}
</style>

<script>
import '@/assets/sass/widgets/widgets.scss';

export default {
    data() {
        return {
            incomes: [],
            asyncLoading: false,
            sa: null,
            assignments: [],
            timeDifference: '',
            interval: null,
            currentTime: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            timer: null,
        }
    },
    async mounted() {
        this.sa = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
        await this.fetchAssignments();
        this.updateTimeDifference(); // Update once initially
        this.interval = setInterval(this.updateTimeDifference, 1000); // Update every second
        this.timer = setInterval(() => {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        }, 1000);
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    destroyed() {
        clearInterval(this.timer);
    },
    computed: {
        customerAvatar() {
            if (this.mostRecentAssignment && this.mostRecentAssignment.Customer && this.mostRecentAssignment.Customer.CustomerDocuments && this.mostRecentAssignment.Customer.CustomerDocuments.length > 0) {
                let documents = this.mostRecentAssignment.Customer.CustomerDocuments;
                return documents.find((doc) => doc.isProfilePic).storage_url;
            } else {
                return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
            }
        },
        mostRecentAssignment() {
            // const now = this.$moment('28-12-2023 19:58:00', 'DD-MM-YYYY HH:mm');
            const now = this.$moment();
            // const currentTime = this.$moment('25-01-2025 08:30', 'DD-MM-YYYY HH:mm');
            const recentAssignment = this.findRecentAssignment(now, this.assignments)
            return recentAssignment
        }
    },
    methods: {
        formatDate(date) {
            return this.$moment(date).format('MMM Do, YYYY');
        },
        formatTime(time) {
            let timeToFormat = '2000-01-01 ' + time;
            return this.$moment(timeToFormat).format('LT');
        },
        getCustomerAddress(){
            let address = null;
            let city = null;
            let state = null;
            let pincode = null;
            if(this.mostRecentAssignment){
                if(this.mostRecentAssignment.Customer && this.mostRecentAssignment.Customer.address){
                    address = this.mostRecentAssignment.Customer.address + ','
                }else{
                    address = ''
                }
                if(this.mostRecentAssignment.Customer && this.mostRecentAssignment.Customer.City){
                    city = this.mostRecentAssignment.Customer.City.name + ','
                } 
                if(this.mostRecentAssignment.Customer && this.mostRecentAssignment.Customer.State) {
                    state = this.mostRecentAssignment.Customer.State.name + ','
                }
                if(this.mostRecentAssignment.Customer && this.mostRecentAssignment.Customer.Pincode) {
                    pincode = this.mostRecentAssignment.Customer.Pincode.pincode
                }
                
            }
            return `${address} ${city} ${state} ${pincode}`
        },
        findRecentAssignment(now, assignments) {
            let upcomingAssignment = null;
            let smallestTimeDifference = Number.MAX_VALUE;
            const yesterday = now.clone().subtract(1, 'days').format('YYYY-MM-DD');
            const filteredAssignment = this.findCurrentOrNextAssignment(now, assignments)
            
            for (const assignment of filteredAssignment) {
                const startDate = this.$moment(assignment.start_date, 'YYYY-MM-DD');
                const endDate = this.$moment(assignment.end_date, 'YYYY-MM-DD');

                for (let date = startDate; date.isSameOrBefore(endDate); date.add(1, 'days')) {
                    let shiftStart = date.clone().set({
                        hour: this.$moment(assignment.shiftStartTime, 'HH:mm').get('hour'),
                        minute: this.$moment(assignment.shiftStartTime, 'HH:mm').get('minute')
                    });
                    let shiftEnd = shiftStart.clone().set({
                        hour: this.$moment(assignment.shiftEndTime, 'HH:mm').get('hour'),
                        minute: this.$moment(assignment.shiftEndTime, 'HH:mm').get('minute')
                    });

                    // Adjust for night shift
                    if (shiftEnd.isBefore(shiftStart)) {
                        shiftEnd.add(1, 'days');
                    }

                    // Exclude current and past shifts
                    if (now.isBefore(shiftStart)) {
                        const timeDifference = shiftStart.diff(now);
                        if (timeDifference < smallestTimeDifference) {
                            smallestTimeDifference = timeDifference;
                            upcomingAssignment = {
                                ...assignment,
                                assignmentDateTime: shiftStart.format('YYYY-MM-DD HH:mm')
                            };
                        }
                    }
                }
            }

            return upcomingAssignment;
        },
        findCurrentOrNextAssignment(now, assignments) {
            const yesterday = now.clone().subtract(1, 'days').format('YYYY-MM-DD');
            const today = now.format('YYYY-MM-DD');

            // Filter for current or just started assignments
            let currentOrJustStartedAssignments = assignments.filter(assignment => {
                return this.$moment(today).isBetween(assignment.start_date, assignment.end_date, null, '[]') ||
                    this.$moment(yesterday).isBetween(assignment.start_date, assignment.end_date, null, '[]');
            });

            // Sort these assignments by start date
            currentOrJustStartedAssignments.sort((a, b) => this.$moment(a.start_date).diff(this.$moment(b.start_date)));

            // Find the next assignment only if needed
            let nextAssignment = null;
            if (currentOrJustStartedAssignments.length === 0 ||
                this.$moment(currentOrJustStartedAssignments[currentOrJustStartedAssignments.length - 1].end_date).isBefore(today)) {
                nextAssignment = assignments.find(assignment => this.$moment(assignment.start_date).isAfter(today));
            }

            // Combine the lists and return
            if (nextAssignment) {
                currentOrJustStartedAssignments.push(nextAssignment);
            }

            return currentOrJustStartedAssignments;
        },
        
        updateTimeDifference() {
            if (this.mostRecentAssignment && this.mostRecentAssignment.id) {
                const now = this.$moment();
                // const now = this.timeNow
                
                const assignmentDateTime = this.$moment(this.mostRecentAssignment.assignmentDateTime);

                const duration = this.$moment.duration(assignmentDateTime.diff(now));
                // this.timeDifference = `${duration.days()} days ${duration.hours()} hours ${duration.minutes()} minutes ${duration.seconds()} seconds`;
                this.timeDifference = {
                    days: duration.days(),
                    hours: duration.hours(),
                    minutes: duration.minutes(),
                    seconds: duration.seconds()
                };
                if(duration._milliseconds <= 0){
                    this.$store.state.events.event_assignment_active = true
                    this.fetchAssignments()
                }
            }
        },
        formatShortDate(date) {
            const curentDate = this.$moment(date).fromNow()
            return curentDate

        },

        async fetchAssignments() {
            this.asyncLoading = true
            if (this.$store.state.employees && this.$store.state.employees.employee) {
                const employeeId = this.$store.state.employees.employee.uuid
                const assignments = await this.$store.dispatch('employees/getEmployeeAssignmentsViewByEmpId', employeeId)
                assignments.sort((a, b) => this.$moment(a.start_date) - this.$moment(b.start_date));

                this.assignments = assignments;
            }
            this.asyncLoading = false
        },
    }
}
</script>
