const Module = () => import("./Module.vue");
const Login = () => import("./views/LoginPage.vue");
const PasswordRecovery = () => import("./views/PasswordRecovery.vue");
const PasswordReset = () => import("./views/PasswordReset.vue");
const Profile = () => import("./views/Profile.vue");
const RolesPermissions = () => import("./views/RolesPermission.vue");

const moduleRoute = {
  path: "/login",
  component: Module,
  children: [
    {
      path: "/",
      name: "login",
      component: Login,
      meta: { layout: 'auth' }
    },
    {
      path: "/password-recovery",
      name: "password-recovery",
      component: PasswordRecovery,
      meta: { layout: 'auth' }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {}
    },
    {
      path: "/profile/change-password",
      name: "change-password",
      component: PasswordReset,
      meta: {}
    },
    
    {
      path: "/roles-permissions",
      name: "roles-permissions",
      component: RolesPermissions,
      meta: {}
    }
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
