// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4lK1Fvni5NE9P0rPPAxdQCPfFAQnEyWo",
  authDomain: "epspwa.firebaseapp.com",
  projectId: "epspwa",
  storageBucket: "epspwa.appspot.com",
  messagingSenderId: "732625594653",
  appId: "1:732625594653:web:f0f49055fad950511d59f4",
  measurementId: "G-Y69ZLC846Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export default messaging;
