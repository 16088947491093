import * as types from "./mutation-types";

export default {
    RESET_STATE(state) {
        Object.assign(state, {
            fcmToken: null,
            employees:[],
            managers:[],
            employee:null,
            onlineEmployees: [],
            scannedEmployee:null,
            addedEmployee:null,
            addedDocument:null,
            addedDocuments:[],
            employee_assignments:[],
            addedEmployeeAssignments:[],
            nowEmployeeAssignment:null,
            employee_other_attendances:[],
            employee_other_attendances_today:[],
            addedEmployeeOtherAttendance:null,
            incomes:[],
            employee_requests:[],
        });
    },
    setIsOnline(state, payload) {
        state.isOnline = payload;
    },
    setEmployees(state, payload) {
        state.employees = payload;
    },
    setFcmToken(state, token) {
        state.fcmToken = token;
      },
    setOnlineEmployees(state, employees) {
        state.onlineEmployees = employees;
    },
    addOnlineUser(state, employee) {
        if (!state.onlineEmployees.some(emp => emp.uuid === employee.uuid)) {
            state.onlineEmployees.push(employee);

            let message = null;
            if(employee.managerId === state.employee.uuid){
                message = `${employee.name} is online`
                 // Add a notification for the user
                const notification = {
                    name: message,
                    time: new Date().toISOString(),
                    status: 'pending',
                };
                if (state.notifications.length >= 5) {
                    state.notifications.shift(); // Keep only the latest 5 notifications
                }
                state.notifications.push(notification);
            }
            
        }
    },
    updateAllNotificationToRead(state) {
        const notifications = state.notifications;
        notifications.forEach(notification => {
            notification.status = 'read';
        });
        state.notifications = notifications;
    },
    removeOnlineUser(state, userId) {
        state.onlineEmployees = state.onlineEmployees.filter(emp => emp.user_id !== userId);
    },
    setAddedEmployee(state, payload) {
        state.addedEmployee = payload;
    },
    setAddedDocument(state, payload) {
        state.addedDocument = payload;
    },
    setAddedDocuments(state, payload) {
        state.addedDocuments = payload;
    },
    setEmployee(state, payload) {
        state.employee = payload;
    },
    setEmployeeAssignments(state, payload) {
        state.employee_assignments = payload;
    },
    setAddedEmployeeAssignments(state, payload) {
        state.addedEmployeeAssignments = payload;
    },
    setNowEmployeeAssignment(state, payload){
        state.nowEmployeeAssignment = payload
    },
    setScannedEmployee(state, payload){
        state.scannedEmployee = payload
    },
    setEmployeeOtherAttendances(state, payload){
        state.employee_other_attendances = payload
    },
    setAddedEmployeeOtherAttendance(state, payload){
        state.addedEmployeeOtherAttendance = payload
    },
    setEmployeeOtherAttendancesToday(state, payload){
        state.employee_other_attendances_today = payload
    },
    setIncomes(state, payload){
        state.incomes = payload
    },
    setManagers(state, payload){
        state.managers = payload
    },
    setEmployeeRequests(state, payload){
        state.employee_requests = payload
    },
    setEmployeeLeaveRequests(state, payload){
        state.employee_leave_requests = payload
    },
    setEmployeeTransactionRequests(state, payload){
        state.employee_transaction_requests = payload
    },
    setEmployeeTransferRequests(state, payload){
        state.employee_transfer_requests = payload
    },
    setEmployeeInventoryRequests(state, payload){
        state.employee_inventory_requests = payload
    },
    setEmployeeLeaves(state, payload){
        state.employee_leaves = payload
    },
    setEmployeeInventories(state, payload){
        state.employee_inventories = payload
    },
    
    // Mutation to add a user ID to the pending fetches
    markUserFetchInProgress(state, userId) {
        if (!state.pendingUserFetches.includes(userId)) {
        state.pendingUserFetches.push(userId);
        }
    },

    // Mutation to remove a user ID from the pending fetches
    unmarkUserFetchInProgress(state, userId) {
        state.pendingUserFetches = state.pendingUserFetches.filter(id => id !== userId);
    },
    MARK_ATTENDANCE(state, payload) {
        state.markedAttendanceForId = payload;
    },
};
