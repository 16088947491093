const Module = () => import("./Module.vue");

const moduleRoute = {
  path: "/events",
  component: Module,
  
};

export default router => {
  router.addRoutes([moduleRoute]);
};
