<template>
<!--  BEGIN SIDEBAR  -->
<div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
        <div class="shadow-bottom"></div>

        <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">

            <!-- HOME -->
            <router-link tag="li" to="/" class="menu" @click.native="toggleMobileMenu">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        <span>{{ $t('dashboard') }}</span>
                    </div>
                </a>
            </router-link>
            <!-- EMPLOYEES -->
            <li class="menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_employees')">
                <a href="#employees" v-b-toggle class="dropdown-toggle" @click.prevent>
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield" data-v-02c2cbc4="">
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                        </svg>
                        <span>{{ $t('employees') }}</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <b-collapse id="employees" accordion="menu">
                    <ul class="collapse submenu list-unstyled show">
                        <router-link tag="li" to="/employees" @click.native="toggleMobileMenu"><a>All Employees</a></router-link>
                        <router-link tag="li" to="/employees/add" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_employees_add')"><a>Add Employees</a></router-link>
                    </ul>
                </b-collapse>
            </li>

            <!-- CUSTOMERS -->
            <li class="menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_customers')">
                <a href="#customers" v-b-toggle class="dropdown-toggle" @click.prevent>
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 1h12M3 1v16M3 1H2m13 0v16m0-16h1m-1 16H3m12 0h2M3 17H1M6 4h1v1H6V4Zm5 0h1v1h-1V4ZM6 8h1v1H6V8Zm5 0h1v1h-1V8Zm-3 4h2a1 1 0 0 1 1 1v4H7v-4a1 1 0 0 1 1-1Z" />
                        </svg>
                        <span>{{ $t('customers') }}</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <b-collapse id="customers" accordion="menu">
                    <ul class="collapse submenu list-unstyled show">
                        <router-link tag="li" to="/customers" @click.native="toggleMobileMenu"><a>All Customers</a></router-link>
                        <router-link tag="li" to="/customers/add" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_customers_add')"><a>Add Customers</a></router-link>
                    </ul>
                </b-collapse>
            </li>

            <!-- Vendors -->
            <li class="menu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_vendors')">
                <a href="#vendors" v-b-toggle class="dropdown-toggle" @click.prevent>
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.308 9a2.257 2.257 0 0 0 2.25-2.264 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 0 0 4.5 0 2.25 2.25 0 1 0 4.5 0C19.058 5.471 16.956 1 16.956 1H3.045S1.058 5.654 1.058 6.736A2.373 2.373 0 0 0 3.308 9Zm0 0a2.243 2.243 0 0 0 1.866-1h.767a2.242 2.242 0 0 0 3.733 0h.767a2.242 2.242 0 0 0 3.733 0h.767a2.247 2.247 0 0 0 1.867 1A2.22 2.22 0 0 0 18 8.649V19H9v-7H5v7H2V8.524c.37.301.83.469 1.308.476ZM12 12h3v3h-3v-3Z" />
                        </svg>
                        <span>{{ $t('vendors') }}</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <b-collapse id="vendors" accordion="menu">
                    <ul class="collapse submenu list-unstyled show">
                        <router-link tag="li" to="/vendors" @click.native="toggleMobileMenu"><a>All Vendors</a></router-link>
                        <router-link tag="li" to="/vendors/add" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_vendors_add')"><a>Add Vendors</a></router-link>
                    </ul>
                </b-collapse>
            </li>

            <hr>
            <router-link tag="li" to="/customers/contracts" class="menu" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_contracts')">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 1v4a1 1 0 0 1-1 1H1m8-2h3M9 7h3m-4 3v6m-4-3h8m3-11v16a.969.969 0 0 1-.932 1H1.934A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.829 1h8.239A.969.969 0 0 1 15 2ZM4 10h8v6H4v-6Z" />
                        </svg>

                        <span> Contracts </span>
                    </div>
                </a>
            </router-link>
            <router-link tag="li" to="/employees/assignments" class="menu" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_assignments')">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M1 10c1.5 1.5 5.25 3 9 3s7.5-1.5 9-3m-9-1h.01M2 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1ZM14 5V3a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v2h8Z" />
                        </svg>
                        <span> Assignments </span>
                    </div>
                </a>
            </router-link>
            <router-link tag="li" to="/attendances" class="menu" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_attendances')">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z" />
                        </svg>
                        <span> Attendance </span>
                    </div>
                </a>
            </router-link>

            <!-- Requests -->
            <li class="menu">
                <a href="#requests" v-b-toggle class="dropdown-toggle" @click.prevent v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests')">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                        </svg>

                        <span>Requests</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <b-collapse id="requests" accordion="menu">
                    <ul class="collapse submenu list-unstyled show">
                        <router-link tag="li" to="/employees/requests/leave" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_leave')"><a>Leave Applications</a></router-link>
                        <router-link tag="li" to="/employees/requests/advance" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_advance')"><a>Advance Requests</a></router-link>
                        <router-link tag="li" to="/employees/requests/inventory" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_inventory')"><a>Inventory Requests</a></router-link>
                        <router-link tag="li" to="/employees/requests/transfer" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_requests_transfer')"><a>Transfer Requests</a></router-link>
                    </ul>
                </b-collapse>
            </li>
            <router-link tag="li" to="/inventory" class="menu" @click.native="toggleMobileMenu" v-if="permissions && permissions.length > 0 && permissions.includes('nav_inventories')">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package" data-v-02c2cbc4="">
                            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <span> Inventory </span>
                    </div>
                </a>
            </router-link>
            <hr>

            <router-link tag="li" to="/pages/training-materials" class="menu" @click.native="toggleMobileMenu">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 1v4a1 1 0 0 1-1 1H1m14 12a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v16ZM5 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm5.697 2.395v-.733l1.268-1.219v2.984l-1.268-1.032Z" />
                        </svg>
                        <span> Training Materials </span>
                    </div>
                </a>
            </router-link>
            <li class="menu">
                <a class="dropdown-toggle" href="tel:919322295293">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-forwarded" data-v-02c2cbc4="">
                            <polyline points="19 1 23 5 19 9"></polyline>
                            <line x1="15" y1="5" x2="23" y2="5"></line>
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                        <span> Contact Us </span>
                    </div>
                </a>
            </li>
            <router-link tag="li" to="/pages/about-us" class="menu" @click.native="toggleMobileMenu">
                <a class="dropdown-toggle">
                    <div class="">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <span> About Us </span>
                    </div>
                </a>
            </router-link>
            <hr>
            <li class="menu" style=" background: #191e3a;" >
                <a class="dropdown-toggle" @click="logoutHandler">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                            <polyline points="16 17 21 12 16 7"></polyline>
                            <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        <span> Log Out </span>
                    </div>
                </a>
            </li>

            <!--</ul>-->
        </perfect-scrollbar>
    </nav>
</div>
<!--  END SIDEBAR  -->
</template>

<script>
export default {
    data() {
        return {
            menu_collapse: 'dashboard'
        };
    },

    watch: {
        $route(to) {
            const selector = document.querySelector('#sidebar a[href="' + to.path + '"]');
            if (selector) {
                const ul = selector.closest('ul.collapse');
                if (!ul) {
                    const ele = document.querySelector('.dropdown-toggle.not-collapsed');
                    if (ele) {
                        ele.click();
                    }
                }
            }
        }
    },

    mounted() {
        // default menu selection on refresh
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    },
    computed:{
        permissions(){
            return this.$store.state.auth.permissions;
        }
    },

    methods: {
        toggleMobileMenu() {
            if (window.innerWidth < 991) {
                this.$store.commit('toggleSideBar', !this.$store.state.is_show_sidebar);
            }
        },
        async logoutHandler(){
                await this.$store.dispatch('auth/logout')
                this.$router.push('/login');
            }
    }
};
</script>
