export default () => ({
    fcmToken: null,
    employees:[],
    managers:[],
    employee:null,
    onlineEmployees: [],
    scannedEmployee:null,
    addedEmployee:null,
    addedDocument:null,
    addedDocuments:[],
    employee_assignments:[],
    addedEmployeeAssignments:[],
    nowEmployeeAssignment:null,
    employee_other_attendances:[],
    employee_other_attendances_today:[],
    addedEmployeeOtherAttendance:null,
    incomes:[],
    employee_requests:[],
    employee_leave_requests:[],
    employee_transaction_requests:[],
    employee_transfer_requests:[],
    employee_inventory_requests:[],
    employee_leaves:[],
    employee_inventories:[],
    pendingUserFetches:[],
    isOnline: false,
    notifications:[],
    markedAttendanceForId:null,
});
