import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
require('dotenv').config();
import moment from 'moment';

// Set Moment.js globally
Vue.prototype.$moment = moment;

//bootstrap vue
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

//Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
    confirmButtonColor: '#4361ee',
    cancelButtonColor: '#e7515a'
};
Vue.use(VueSweetalert2, options);

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './i18n';

// vue-mask
import VueMask from 'v-mask';
Vue.use(VueMask);




Vue.config.productionTip = false;

// set default settings
import appSetting from './app-setting';

import './registerServiceWorker'
Vue.prototype.$appSetting = appSetting;
appSetting.init();

//Import Modules With routes

import authModule from "./modules/auth";
import employeesModule from "./modules/employees";
import customersModule from "./modules/customers";
import businessModule from "./modules/business";
import eventModule from "./modules/events";
import vendorsModule from "./modules/vendors";
import inventoriesModule from "./modules/inventories";

import { registerModules } from "./register-modules";

// console.log('env',process.env);
console.log('APP URL: ',process.env.VUE_APP_companyName);

registerModules({
  auth: authModule,
  employees: employeesModule,
  customers: customersModule,
  vendors: vendorsModule,
  inventories: inventoriesModule,
  business: businessModule,
  events: eventModule
});

console.log('ENV VARIABLE _SV - ', process.env.VUE_APP_ENV);

if ('serviceWorker' in navigator) {
navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
    console.log('Service Worker registered', registration);
    })
    .catch((err) => {
    console.log('Service Worker registration failed', err);
    });
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');