<template>
<div class="widget widget-dailysales" v-if="activeAssignment">
    <div class="widget-heading">
        <div>
            <h5>Active</h5>
            <template v-if="other_attendance && other_attendance.id">
                <b-badge v-if="['present', 'approved'].includes(other_attendance.status)" variant="outline-success" class="outline-badge-success mr-1">
                    {{ other_attendance.status }}
                </b-badge>
                <b-badge v-else-if="other_attendance.status === 'marked'" variant="outline-info" class="outline-badge-info mr-1">
                    {{ other_attendance.status }}
                </b-badge>
                <b-badge v-else-if="other_attendance.status === 'escalated'" variant="outline-warning" class="outline-badge-warning mr-1">
                    {{ other_attendance.status }}
                </b-badge>
                <b-badge v-else-if="['declined', 'absent'].includes(other_attendance.status)" variant="outline-danger" class="outline-badge-danger mr-1">
                    {{ other_attendance.status }}
                </b-badge>
            </template>
            <span class="sub-title" v-else> Attendance Status </span>

        </div>
        <div :class="activeAssignment ? 'w-icon text-success bg-success' : 'w-icon text-warning bg-warning'">
            <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </div>

    <div class="widget-content text-white" v-if="activeAssignment">
        <div class="d-flex justify-content-center flex-column m-2">
            <p class="text-center text-muted">{{currentTime}}</p>
        </div>
        <div class="d-flex">
            <b-avatar :src="customerAvatar" size="4rem"></b-avatar>
            <div class="ml-2 d-flex align-items-center">
                <h5 class="card-user_name">{{ activeAssignment && activeAssignment.customer_name || null}}</h5>
            </div>
        </div>
        <div class="ml-2 d-flex align-items-center">
                <p class="card-user_name">{{ getCustomerAddress() }}</p>
            </div>
        <div class="d-flex flex-row justify-content-between" v-if="activeAssignment && !asyncLoading">
            <div class="d-flex flex-column">
                <p class="text-muted mt-2">Date</p>
                <p class="mb-1">{{ formatDate(activeAssignment.assignmentDate) }}</p>
                <!-- <p class="mb-1">Income - <span>&#8377;</span> {{ Math.round(activeAssignment.earnings) }}</p> -->
            </div>
            <div class="d-flex flex-column">
                <p class="text-muted mt-2">Shift Time</p>
                <p class="mb-1">{{ formatTime(activeAssignment.shiftStartTime) }} - {{ formatTime(activeAssignment.shiftEndTime) }}</p>
                <!-- <p class="mb-1">Income - <span>&#8377;</span> {{ Math.round(activeAssignment.earnings) }}</p> -->
            </div>
            
        </div>
        <b-button variant="primary" block class="mb-2 mt-2" @click="checkIn" v-if="!other_attendance.id">Check In</b-button>
        <b-button variant="primary" block class="mb-2 mt-2" @click="checkOut" v-if="other_attendance.id && other_attendance.check_in_time && !other_attendance.check_out_time">Check Out</b-button>
    </div>

</div>
<div class="widget text-white" v-else>
    <p class="sub-title text-center p-4"> No Active Assignments </p>
</div>
</template>

<style scoped>
p.sv-time {
    margin: 0 auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    margin-bottom: 0;
    background: #4361ee;
    color: #eaf1ff;
    font-weight: 700;
    font-size: 18px;
    z-index: 7;
    position: relative;
}

p.sv-time span {
    align-self: center;
}
</style>

<script>
import '@/assets/sass/widgets/widgets.scss';

export default {
    data() {
        return {
            incomes: [],
            asyncLoading: false,
            sa: null,
            assignments: [],
            currentTime: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            timer: null,
            other_attendance: {
                id: null,
                employeeId: null,
                assignmentId: null,
                contractDetailId: null,
                date: null,
                check_in_time: null,
                check_out_time: null,
                approved_by: null,
                status: null,
                notes: null,
            },
            timeNow: this.$moment()
        }
    },
    async mounted() {
        this.sa = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
        });
        await this.fetchAssignments();
        this.timer = setInterval(() => {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        }, 1000);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    watch: {
        eventData(newVal) {
            // Handle the change
            console.log(newVal);
            if(newVal === true) {
                this.fetchAssignments()
            }
        }
    },
    computed: {
        eventData(){
            return this.$store.state.events.event_assignment_active
        },
        customerAvatar() {
            if (this.activeAssignment && this.activeAssignment.Customer && this.activeAssignment.Customer.CustomerDocuments && this.activeAssignment.Customer.CustomerDocuments.length > 0) {
                let documents = this.activeAssignment.Customer.CustomerDocuments;
                return documents.find((doc) => doc.isProfilePic).storage_url;
            } else {
                return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
            }
        },
        activeAssignment() {
            const currentTime = this.$moment(); // or any specific time
            // const currentTime = this.$moment('28-12-2023 20:00', 'DD-MM-YYYY HH:mm');
            // const currentTime = this.$moment(this.timeNow, 'DD-MM-YYYY HH:mm');

            const assignments = this.assignments
            const relevantAssignment = this.findRelevantAssignments(currentTime, assignments)
            // const isInShift = this.isCurrentTimeInShift(currentTime, assignments);

            const isInShift = this.isCurrentTimeInShift(currentTime, relevantAssignment);
            if (isInShift && isInShift.id) this.findOtherAttendance(isInShift)
            return isInShift
            // return currentTime
        },
    },
    methods: {
        
        formatDate(date) {
            return this.$moment(date).format('MMM Do, YYYY');
        },
        formatTime(time) {
            let timeToFormat = '2000-01-01 ' + time;
            return this.$moment(timeToFormat).format('LT');
        },
        getCustomerAddress(){
            let address = null;
            let city = null;
            let state = null;
            let pincode = null;
            if(this.activeAssignment){
                if(this.activeAssignment.Customer && this.activeAssignment.Customer.address){
                    address = this.activeAssignment.Customer.address + ','
                }else{
                    address = ''
                }
                if(this.activeAssignment.Customer && this.activeAssignment.Customer.City){
                    city = this.activeAssignment.Customer.City.name + ','
                } 
                if(this.activeAssignment.Customer && this.activeAssignment.Customer.State) {
                    state = this.activeAssignment.Customer.State.name + ','
                }
                if(this.activeAssignment.Customer && this.activeAssignment.Customer.Pincode) {
                    pincode = this.activeAssignment.Customer.Pincode.pincode
                }
                
            }
            return `${address} ${city} ${state} ${pincode}`
        },
        async findOtherAttendance(isInShift) {
            let currentData = {
                id: isInShift.employeeId,
                currentDate: isInShift.assignmentDate
            }
            const otherAttedances = await this.$store.dispatch('employees/getCurrentTimeEmployeeAttendanceByUUID', currentData)
            console.log('other -', otherAttedances)
            const currentAttendance = otherAttedances.find((item) => {
                return item.contractDetailId === isInShift.contractDetailId &&
                    item.assignmentId === isInShift.id;
            });

            if (currentAttendance && currentAttendance != undefined) {
                this.other_attendance = {
                    ...currentAttendance
                }
            }
        },
        findRelevantAssignments(now, assignments) {
            const yesterday = now.clone().subtract(1, 'days').format('YYYY-MM-DD');
            const today = now.format('YYYY-MM-DD');

            // Filter and sort assignments relevant for today and yesterday
            const relevantAssignments = assignments.filter(assignment => {
                return (assignment.start_date <= today && assignment.end_date >= today) ||
                    (assignment.start_date <= yesterday && assignment.end_date >= yesterday);
            }).sort((a, b) => {
                return this.$moment(a.start_date).diff(this.$moment(b.start_date));
            });

            return relevantAssignments;
        },
        isCurrentTimeInShift(now, assignments) {
            const yesterday = now.clone().subtract(1, 'days').format('YYYY-MM-DD');
            const today = now.clone().startOf('day');

            for (const assignment of assignments) {
                const startDate = this.$moment(yesterday, 'YYYY-MM-DD');
                const endDate = this.$moment(assignment.end_date, 'YYYY-MM-DD');
                for (let date = startDate; date.isSameOrBefore(endDate); date.add(1, 'days')) {
                    // One Hour Before
                    // let shiftStart = date.clone().set({
                    //     hour: this.$moment(assignment.shiftStartTime, 'HH:mm').get('hour'),
                    //     minute: this.$moment(assignment.shiftStartTime, 'HH:mm').get('minute')
                    // }).subtract(1, 'hours');
                    // let shiftEnd = date.clone().set({
                    //     hour: this.$moment(assignment.shiftEndTime, 'HH:mm').get('hour'),
                    //     minute: this.$moment(assignment.shiftEndTime, 'HH:mm').get('minute')
                    // }).add(1, 'hours');
                    // Shift TIme.
                    let shiftStart = date.clone().set({
                        hour: this.$moment(assignment.shiftStartTime, 'HH:mm').get('hour'),
                        minute: this.$moment(assignment.shiftStartTime, 'HH:mm').get('minute')
                    });
                    let shiftEnd = date.clone().set({
                        hour: this.$moment(assignment.shiftEndTime, 'HH:mm').get('hour'),
                        minute: this.$moment(assignment.shiftEndTime, 'HH:mm').get('minute')
                    });
                    
                   
                    // Check if the shift crosses midnight
                    if (shiftEnd.isBefore(shiftStart)) {
                        shiftEnd.add(1, 'days');
                    }
                    // Check if now is within the shift time
                    if (now.isBetween(shiftStart, shiftEnd, null, '[]')) {
                        return {
                            ...assignment,
                            assignmentDate: date.format('YYYY-MM-DD')
                        };
                    }
                }
            }
            return null;
        },
        formatShortDate(date) {
            const curentDate = this.$moment(date).fromNow()
            return curentDate

        },
        async fetchAssignments() {
            this.asyncLoading = true
            if (this.$store.state.employees && this.$store.state.employees.employee) {
                const employeeId = this.$store.state.employees.employee.uuid
                const assignments = await this.$store.dispatch('employees/getEmployeeAssignmentsViewByEmpId', employeeId)
                assignments.sort((a, b) => this.$moment(a.start_date) - this.$moment(b.start_date));
                this.assignments = assignments;
            }
            this.asyncLoading = false
        },
        async checkIn() {
           
            if (!this.activeAssignment && this.other_attendance.id) return;
            
            const data = {
                employeeId: this.activeAssignment.employeeId,
                assignmentId: this.activeAssignment.id,
                contractDetailId: this.activeAssignment.contractDetailId,
                date: this.activeAssignment.assignmentDate,
                check_in_time: this.currentTime,
                check_out_time: null,
                approved_by: null,
                status: 'marked',
                notes: null,
            }
            await this.$store.dispatch('employees/addEmployeeOtherAttendance', data).then((res) => {
                this.other_attendance = {...this.$store.state.employees.addedEmployeeOtherAttendance}
                this.sa.fire({
                    icon: 'success',
                    title: 'Checked In Successfully!'
                })
            }).catch((err) => {
                this.sa.fire({
                    icon: 'error',
                    title: 'Check In Failed'
                })
            })
            
        },
        async checkOut(){
          
            if (!this.activeAssignment && !this.other_attendance.id) return;
            if (!this.other_attendance.check_in_time) return;
            let shiftEndTime = this.activeAssignment.shiftEndTime
            let shiftStartTime = this.activeAssignment.shiftStartTime
            let shiftEndTimeDate = this.$moment(shiftEndTime, 'HH:mm:ss')
            let shiftStartTimeDate = this.$moment(shiftStartTime, 'HH:mm:ss')
            let shiftEndTimeDateWithDate = null
            if (shiftEndTimeDate.isBefore(shiftStartTimeDate)) {
                shiftEndTimeDateWithDate = this.$moment(shiftEndTime, 'HH:mm:ss').add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
            } else {
                shiftEndTimeDateWithDate = this.$moment(shiftEndTime, 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            }
            // continue with  the actual method now
            
            if (this.$moment(this.currentTime).isBefore(this.$moment(shiftEndTimeDateWithDate))) {
                const result = await this.$swal({
                    title: 'Are you sure?',
                    text: "You are checking out before shift end time!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, do it!'
                });

                if (result.isConfirmed) {
                   await this.updateAttendance()
                }

                
            } else {
                await this.updateAttendance()
            }
        },
        async updateAttendance(){
            // prepare const data with other_attendance.id and check_out_time only
            const data = {
                id: this.other_attendance.id,
                check_out_time: this.currentTime
            }
            
            
            await this.$store.dispatch('employees/updateEmployeeOtherAttendance', data).then((res) => {
                this.other_attendance = {...this.$store.state.employees.addedEmployeeOtherAttendance}
                this.sa.fire({
                    icon: 'success',
                    title: 'Checked Out Successfully!'
                })
            }).catch((err) => {
                this.sa.fire({
                    icon: 'error',
                    title: 'Check Out Failed'
                })
            })
        }
    }
}
</script>
