const Module = () => import("./Module.vue");
const ViewVendors = () => import("./views/ViewVendors.vue");
const AddVendor = () => import("./views/AddVendor.vue");
const EditVendor = () => import("./views/EditVendor.vue");
const ViewVendor = () => import("./views/ViewVendor.vue");

import Store from '../../store'; // Adjust the path as necessary to import your store

const moduleRoute = {
  path: "/vendors",
  component: Module,
  children: [
    {
      path: "/",
      name: "vendors",
      component: ViewVendors,
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        // Check if permissions include the required permission
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_vendors')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "add",
      name: "add_vendors",
      component: AddVendor,
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_vendors_add')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "edit/:id", // Updated to include the parameter 'id'
      name: "edit_vendors",
      component: EditVendor, // Updated to use the EditVendor component
      meta: {  },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_vendors_add')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
    {
      path: "view/:id", // Updated to include the parameter 'id'
      name: "view_vendor",
      component: ViewVendor, // Updated to use the EditVendor component
      meta: { },
      beforeEnter: (to, from, next) => {
        const permissions = Store.state.auth.permissions;
        const userType = Store.state.auth.userData && Store.state.auth.userData.user_type;
        // Check if permissions include the required permission
        if (userType === 'admin' || permissions.includes('nav_vendors')) {
          next(); // Permission is found, continue to the route
        } else {
          next('/'); // Permission not found, redirect to another path
        }
      }
    },
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
