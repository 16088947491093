export default () => ({
    fcmToken: null,
    vendors:[],
    vendor:null,
    scannedVendor:null,
    addedVendor:null,
    addedDocument:null,
    addedDocuments:[],
    isOnline: false,
    notifications:[],
});
