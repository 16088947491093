import axios from 'axios';
import moment from 'moment';
import { encryptData, decryptData } from '../../../utils/encryption';
import io from 'socket.io-client';
import messaging from '@/firebaseInit'; // Import the messaging instance
import { getToken } from "firebase/messaging";

const SOCKET_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_REALTIME_URL// Replace this with your Node.js API base URL

const API_BASE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_API_URL : process.env.VUE_APP_LOCAL_API_URL// Replace this with your Node.js API base URL
const API_MISC_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_MISC_URL : process.env.VUE_APP_LOCAL_MISC_URL// Replace this with your Node.js API base URL
const API_SERVICE_URL = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_SERVICE_URL : process.env.VUE_APP_LOCAL_SERVICE_URL// Replace this with your Node.js API base URL


export default {
  //Products
  async getProducts({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/inventories/products`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const products = response.data.products;
      if (products) {
        commit('setProducts', products);
        return products
      } else {
        throw new Error('Cannot Retreive Products'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async getProductById({ commit }, id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/inventories/products/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const product = response.data.product;
      if (product) {
        return product
      } else {
        throw new Error('Cannot Retreive Product By Id'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async searchProductByName({ commit }, name) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/inventories/products/getbyname`, {
        params: { name },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      commit('setProducts', response.data.products);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async addProduct({ dispatch, commit }, product) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/inventories/products`, product,{ 
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
        }
      });
      
      if (response.data.status) {
        await dispatch('getProducts');
        return response.data.product
      } else {
        throw new Error('Cannot Add Product');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateProduct({ dispatch, commit }, product) {
    const id = product.get('id');
    try {
      const response = await axios.put(`${API_SERVICE_URL}/inventories/products/${id}`, product, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.data.status) {
        await dispatch('getProducts');
        return response.data.product;
      } else {
        throw new Error('Cannot Update Product');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  //Categories
  async getProductCategories({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/inventories/product_categories`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const product_categories = response.data.product_categories;
      if (product_categories) {
        commit('setProductCategories', product_categories);
        return product_categories;
      } else {
        throw new Error('Cannot Retreive Product Categories'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async getProductCategoryById({ commit }, id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/inventories/product_categories/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const product_category = response.data.product_category;
      if (product_category) {
        return product_category
      } else {
        throw new Error('Cannot Retreive Product Category By Id'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async addProductCategory({ dispatch, commit }, product_category) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/inventories/product_categories`, product_category, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      
      if (response.data.status) {
        await dispatch('getProductCategories');
        return response.data.product_category
      } else {
        throw new Error('Cannot Add Product Category');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateProductCategory({ dispatch, commit }, product_category) {
    // product_category is formdata get id
    const id = product_category.get('id');
    try {
      const response = await axios.put(`${API_SERVICE_URL}/inventories/product_categories/${id}`, product_category, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        }
      });
      
      if (response.data.status) {
        await dispatch('getProductCategories');
        return response.data.product_category;
      } else {
        throw new Error('Cannot Update Product Category');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  //Variants
  async addVariant({ dispatch, commit }, variant) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/inventories/variants`, variant, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.variant
      } else {
        throw new Error('Cannot Add Product Variant');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateVariant({ dispatch, commit }, variant) {

    try {
      const response = await axios.put(`${API_SERVICE_URL}/inventories/variants/${variant.id}`, variant, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      if (response.data.status) {
        return response.data.variant;
      } else {
        throw new Error('Cannot Update Product Variant');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

  async addProductVariants({dispatch, commit}, productVariants){
  
    try {
      let addedVariants = []
      for (const variant of productVariants) {
        const formData = new FormData();
        // Assuming 'variant' is a file object or similar
        
        if(variant.productId){
          const response = await axios.post(`${API_SERVICE_URL}/inventories/variants`, variant, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
    
          if (response.data.status) {
            // commit('setAddedDocument', response.data.customer_variant);
            addedVariants.push(response.data.variant)
            console.log(' Variant Added- ', response.data.variant)
          } else {
            throw new Error('Cannot ADD Variant');
          }
        }
        
  
      }
      commit('setAddedVariants', addedVariants);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';
  
      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },

  async updateProductVariants({dispatch, commit}, productVariants){
  
    try {
      let addedVariants = []
      for (const variant of productVariants) {
        
        if(variant.productId){
          const response = await axios.put(`${API_SERVICE_URL}/inventories/variants/${variant.id}`, variant, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
          });
    
          if (response.data.status) {
            // commit('setAddedDocument', response.data.customer_variant);
            addedVariants.push(response.data.variant)
            console.log(' Variant Updated- ', response.data.variant)
          } else {
            throw new Error('Cannot Update Variant');
          }
        }
        
      }
      commit('setAddedVariants', addedVariants);
    } catch (error) {
      let errorMessage = 'Unknown error occurred';
  
      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
          if (error.response.data.errors && error.response.data.errors.length > 0) {
              // If there are multiple validation errors, concatenate their messages
              errorMessage = error.response.data.errors.map(err => err.message).join(", ");
          } else {
              // Use the main error message if available
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error instanceof Error) {
          // Handle generic Error instances
          errorMessage = error.message;
      } else {
          // Handle cases where error is not an instance of Error
          errorMessage = `Error - ${error.toString()}`;
      }
  
      throw new Error(errorMessage);
    }
  },

  //Employee Inventories
  async getEmployeeInventories({ commit }, { page = null, pageSize = null } = {}) {
    try {
      const params = {};
      if (page !== null && pageSize !== null) {
        params.page = page;
        params.pageSize = pageSize;
      }
      const response = await axios.get(`${API_SERVICE_URL}/inventories/employee_inventories`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_inventories = response.data.employee_inventories;
      if (employee_inventories) {
        commit('setEmployeeInventories', employee_inventories);
        return employee_inventories;
      } else {
        throw new Error('Cannot Retreive Employee Inventories'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async getEmployeeInventoryById({ commit }, id) {
    try {
      const response = await axios.get(`${API_SERVICE_URL}/inventories/employee_inventories/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const employee_inventory = response.data.employee_inventory;
      if (employee_inventory) {
        return employee_inventory
      } else {
        throw new Error('Cannot Retreive Employee Inventory By Id'); // Custom error message for API errors
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);

    }
  },

  async addEmployeeInventory({ dispatch, commit }, employee_inventory) {
    try {
      const response = await axios.post(`${API_SERVICE_URL}/inventories/employee_inventories`, employee_inventory, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.data.status) {
        // await dispatch('getEmployeeInventories');
        return response.data.employee_inventory
      } else {
        throw new Error('Cannot Add Employee Inventory');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },
  async updateEmployeeInventory({ dispatch, commit }, employee_inventory) {
    // product_category is formdata get id
    const id = employee_inventory.id;
    try {
      const response = await axios.put(`${API_SERVICE_URL}/inventories/employee_inventories/${id}`, employee_inventory, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.data.status) {
        // await dispatch('getEmployeeInventories');
        return response.data.employee_inventory;
      } else {
        throw new Error('Cannot Update Employee Inventory');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      // Check if the error is a response from the server with structured data
      if (error.response && error.response.data) {
        if (error.response.data.errors && error.response.data.errors.length > 0) {
          // If there are multiple validation errors, concatenate their messages
          errorMessage = error.response.data.errors.map(err => err.message).join(", ");
        } else {
          // Use the main error message if available
          errorMessage = error.response.data.message || errorMessage;
        }
      } else if (error instanceof Error) {
        // Handle generic Error instances
        errorMessage = error.message;
      } else {
        // Handle cases where error is not an instance of Error
        errorMessage = `Error - ${error.toString()}`;
      }

      throw new Error(errorMessage);
    }
  },

};
