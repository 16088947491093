export default () => ({
    manpower_types:[],
    rate_cards:[],
    addedUser:null,
    addedManPowerType:null,
    addedRateCard:null,
    addedContract:null,
    contracts:[],
    addedContractDetail:null,
    contract_details:[],
    contract_detail:null,
    addedShift:null,
    shifts:[],
    employee_assignments:[],
    addedTransaction: null,
    transactions: [],
    transaction: null,
});
