<template>
  <div class="widget widget-recent-orders widget-recent-activity">
    <div class="widget-heading">
      <h5>Inventories</h5>
    </div>
    <div class="widget-content">
      <b-table ref="basic_table" responsive="sm" stacked="md" hover :items="items" :fields="columns" :per-page="table_option.page_size" sort-by="created_at" :show-empty="true">
        <template #cell(name)="row">
          <div class="d-flex">
            <div class="usr-img-frame mr-2 rounded-circle">
              <img :src="getImage(row.item.storage_url)" class="img-fluid rounded-circle" alt="avatar" />
            </div>
            <p class="align-self-center mb-0 admin-name">{{ row.value }}</p>
          </div>
        </template>
        <template #cell(price)="row">
          <p class="align-self-center mb-0 text-wrap">{{ formatCurrency(row.value) }}</p>
        </template>
        <template #cell(ProductCategory)="data">
          <p class="align-self-center mb-0">{{ data.item.ProductCategory ? data.item.ProductCategory.name : 'N/A' }}</p>
        </template>
        <template #cell(ManpowerType)="data">
          <p class="align-self-center mb-0">{{ data.item.ManpowerType ? data.item.ManpowerType.name : 'N/A' }}</p>
        </template>
        <template #cell(Variants)="data">
          <div class="align-self-center mb-0 text-wrap text-white" v-html="formatVariants(data.item.Variants)"></div>
        </template>
      </b-table>
      <div class="tm-action-btn">
      <b-button variant="default" @click="viewAllProducts">
        <span>View All</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-arrow-right"
        >
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      </b-button>
    </div>
    </div>
    
  </div>
</template>


<script>
import '@/assets/sass/widgets/widgets.scss';

export default {
  data() {
    return {
      products: [],
      asyncLoading: false,
      items: [],
      columns: [],
      table_option: { total_rows: 0, current_page: 1, page_size: 10 },
      meta: {},
    };
  },
  async mounted() {
    await this.bind_data();
  },
  destroyed() {},
  watch: {},
  computed: {
    formatVariants() {
      return function (variants) {
        if (!variants || variants.length === 0) return '';
        return variants.map((variant) => `Size ${variant.size} | x ${variant.stock}`).join('<br>');
      };
    },
  },
  methods: {
    viewAllProducts() {
      this.$router.push({ name: 'products' });
    },
    async fetchProducts() {
      this.asyncLoading = true;
      const products = await this.$store.dispatch('inventories/getProducts');
      this.products = [...products];
      this.asyncLoading = false;
    },
    formatCurrency(price) {
      //format price to number
      const amount = parseFloat(price);
      //return '' if not price
      if (isNaN(amount)) return '';
      return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0, // No digits after the decimal point
        maximumFractionDigits: 0, // No digits after the decimal point, ensures rounding
      }).format(amount);
    },
    getImage(imageUrl) {
      if (!imageUrl) {
        return 'https://gms-file.blr1.cdn.digitaloceanspaces.com/No-Image-Placeholder.png';
      } else {
        return imageUrl;
      }
    },

    async bind_data() {
      this.asyncLoading = true;
      try {
        const products = await this.$store.dispatch('inventories/getProducts');
        console.log('Products', products);
        this.columns = [
          { key: 'name', label: 'Name', sortable: true },
          // { key: 'description', label: 'Description', sortable: true, class: 'text-wrap'},
          { key: 'price', label: 'Price', sortable: true },
          { key: 'ProductCategory', label: 'Category' },
          { key: 'Variants', label: 'Variants', class: 'text-wrap' },
          // { key: 'Vendor', label: 'Vendor' },
          { key: 'ManpowerType', label: 'Designation' },
          // { key: 'action', label: 'Actions', class: 'actions text-center' },
        ];
        this.items = products;
        this.table_option.total_rows = this.items.length;
        this.get_meta();
      } catch (error) {
        console.log('error', error);
      }
      this.asyncLoading = false;
    },
    refresh_table(total) {
      this.table_option.total_rows = total;
      this.table_option.currentPage = 1;
    },
    get_meta() {
      var startPage;
      var endPage;
      var totalPages = this.table_option.page_size < 1 ? 1 : Math.ceil(this.table_option.total_rows / this.table_option.page_size);
      totalPages = Math.max(totalPages || 0, 1);

      var maxSize = 5;
      var isMaxSized = typeof maxSize !== 'undefined' && maxSize < totalPages;
      if (isMaxSized) {
        startPage = Math.max(this.table_option.current_page - Math.floor(maxSize / 2), 1);
        endPage = startPage + maxSize - 1;

        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = endPage - maxSize + 1;
        }
      } else {
        startPage = 1;
        endPage = totalPages;
      }
      let startIndex = (this.table_option.current_page - 1) * this.table_option.page_size;
      let endIndex = Math.min(startIndex + this.table_option.page_size - 1, this.table_option.total_rows - 1);

      var pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
      this.meta = {
        total_items: this.table_option.total_rows,
        current_page: this.table_option.current_page,
        page_size: this.table_option.page_size,
        total_pages: totalPages,
        start_page: startPage,
        end_page: endPage,
        start_index: startIndex,
        end_index: endIndex,
        pages: pages,
      };
    },
  },
};
</script>
